import React from 'react'
import './StudentDetails.scss'
import { useAuth } from '../../services/authContext'
import {
  Container,
  Box,
  Fab,
  TextField,
  Button,
  Modal,
  Typography,
  Divider,
  List,
  ListItem,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  ListProductApi,
  ListStudentsApi,
  CreateStudentApi,
  UpdateStudentApi,
  DeleteStudentApi,
  ListClassRelatedToSectionApi
} from '../../services/apis'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import CryptoJS from 'crypto-js';
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../componentConst';
import StudentForm from '../Utility/StudentForm'
import IDCardGenerator from '../Utility/IdCardGenerator'

const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23; // Replace with a secure key

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString); // Parse the JSON string
  } catch (error) {
    console.error('Decryption error:', error);
    return null; // Return null if decryption fails
  }
};


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
    // Add more theme customizations as needed
  }
})



const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%', // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '240px',
  '@media (max-width: 600px)': {
    // Adjust the breakpoint as needed
    width: '100%' // Width for mobile devices
  }
}

const StudentDetails = props => {
  const { loginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [classList, setCLassList] = React.useState([])
  const [selectedID, setSelectedID] = React.useState('')
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false)
  const [timetableData, setTimetableData] = React.useState([])
  const [addedClassFromSection, setAddedClassFromSection] = React.useState([])


  const [openForm, setOpenForm] = React.useState(false);
  const [formMode, setFormMode] = React.useState('create'); // 'create' or 'update'
  const [studentData, setStudentData] = React.useState({});

  React.useEffect(() => {

    if (
      listApiFlag &&
      loginStatus.user &&
      (loginStatus.user.staff_type === 'admin' ||
        loginStatus.user.staff_type === 'teacher')
    ) {
      const fetchListProducts = async () => {
        try {
          const encryptedCreds = localStorage.getItem('loginCred')

          if (!encryptedCreds) {
            throw new Error('No login credentials found in localStorage')
          }

          const decryptedCreds = decryptData(encryptedCreds)

          if (!decryptedCreds || !decryptedCreds.token) {
            throw new Error(
              'Failed to retrieve valid token from decrypted credentials'
            )
          }

          const response = await ListStudentsApi({
            id: loginStatus.user.id
          })
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      const fetchListClasses = async () => {
        try {
          const response = await ListProductApi()
          response.forEach(element => {
            element.title = element.productName
          })
          console.log('getresponse', JSON.stringify(response))
          setCLassList(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
      fetchListClasses()
    }
  }, [loginStatus])

  React.useEffect(() => {
    if (Array.isArray(props.studentData)) {
      setTimetableData(props.studentData)
      console.log('students data', props.studentData)
      classFromSectionsApi(props.studentData[0]?.section_id)
    }
  }, [props.studentData])

  const classFromSectionsApi = async sectionId => {
    const body = { id: sectionId }
    try {
      const classFromSection = await ListClassRelatedToSectionApi(body)
      setAddedClassFromSection(classFromSection)
      console.log('setAdded', classFromSection)
    } catch (error) {
      console.error('Error fetching class data:', error)
    }
  }

  const handleStudentSubmit = async () => {
    const encryptedCreds = localStorage.getItem('loginCred')

    if (!encryptedCreds) {
      throw new Error('No login credentials found in localStorage')
    }

    const decryptedCreds = decryptData(encryptedCreds)

    if (!decryptedCreds || !decryptedCreds.token) {
      throw new Error(
        'Failed to retrieve valid token from decrypted credentials'
      )
    }

    // Proceed with the rest of the logic
    try {
      // Create a FormData object for handling file uploads
      const formData = new FormData();
      formData.append('first_name', studentData.first_name);
      formData.append('last_name', studentData.last_name);
      formData.append('email', studentData.email);
      formData.append('phone', studentData.phone);
      formData.append('address', studentData.address);
      formData.append('dob', studentData.dob);
      formData.append('gender', studentData.gender);
      formData.append('status', studentData.status || 'active');
      formData.append('staff_type', 'student');
      formData.append('date_joined', studentData.dateJoined);
      formData.append('school_name', loginStatus.admin.school_name);
      formData.append('adminToken', decryptedCreds.token);

      // Only append profile_picture_file if it's defined
      if (studentData.profile_picture_file) {
        formData.append('profile_picture', studentData.profile_picture_file);
      }

      if (formMode === 'create') {
        formData.append('password', '1234');
        // formData.append('otp', '1234');
        formData.append('student_class', props.studentData[0]?.section_id);
        formData.append('student_roll_no', studentData.student_roll_no);
      } else if (formMode === 'update') {
        formData.append('item_id', studentData.id);
      }

      console.log(formData.entries()); // Inspect the FormData object

      const response =
        formMode === 'create'
          ? await CreateStudentApi(formData) // No need for JSON.stringify
          : await UpdateStudentApi(formData);

      console.log(`${formMode === 'create' ? 'Created' : 'Updated'} student successfully:`, response);

      // if (formMode === 'create') {
      //   await ForgetPassscodeApi({ phone: studentData.phone });
      //   alert('OTP sent successfully!');
      // }

      window.location.reload();
      // handleClose();
    } catch (error) {
      console.error(`${formMode === 'create' ? 'Creating' : 'Updating'} student failed:`, error);
    }
  };

  const handleDeleteProductModal = async item_id => {
    setDeleteProductOpen(true)
    setSelectedID(item_id)
  }

  const filteredUsers = listOfUsers.filter(user => {
    const firstNameMatches = user.first_name
      ? user.first_name.toLowerCase().includes(filter.toLowerCase())
      : false
    const lastNameMatches = user.last_name
      ? user.last_name.toLowerCase().includes(filter.toLowerCase())
      : false
    const classMatches = user.student_class == props.studentData[0]?.section_id
    return (firstNameMatches || lastNameMatches) && classMatches
  })

  const [open, setOpen] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)

  const handleOpenCreate = () => {
    setFormMode('create');
    setStudentData({});
    setOpenForm(true);
  };

  const handleOpenUpdate = (data) => {
    setFormMode('update');
    console.log(data)
    setStudentData(data);
    setOpenForm(true);
  };

  const handleDeleteProduct = async deleteId => {
    try {
      const formData = new FormData()
      formData.append('id', deleteId)

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteStudentApi(formData)

      console.log('Product deleted successfully:', response)
      // handleClose()
    } catch (error) {
      console.error('Error deleting Product:', error)
    }
  }
  return (
    <div className='StudentDetails' data-testid='StudentDetails'>
      <ThemeProvider theme={theme}>

        <Container
          sx={{
            flexGrow: 1,
            mt: { xs: 2, md: 5 }, // Margin for small and medium screens
            mb: { xs: 5, md: 7 }, // Adjust bottom margin
            paddingBottom: { xs: '80px', md: '80px' },
            // bgcolor: 'background.paper'
          }}>
          {loginStatus.token && (
            <Box>
              {loginStatus.user &&
                (loginStatus.user.staff_type === 'admin' ||
                  loginStatus.user.staff_type === 'teacher') ? (
                <div className='table-cover'>
                  <Box sx={{ position: 'absolute', bottom: 76, right: 16 }}>
                    {loginStatus.user.staff_type != 'teacher' &&
                      <Fab
                        color='primary'
                        aria-label='add'
                        onClick={handleOpenCreate}
                        sx={{
                          position: 'fixed',
                          right: '30px',
                          bottom: '70px'
                        }}
                      >
                        <AddIcon />
                      </Fab>
                    }
                  </Box>
                  <TextField
                    fullWidth
                    label='Filter By Name'
                    id='fullWidth'
                    value={filter}
                    sx={{ marginBottom: 3 }}
                    onChange={e => setFilter(e.target.value)}
                  />
                  {filteredUsers
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(t => t.className === item.className)
                    )
                    .map(item => (
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1-content'
                          id='panel1-header'
                        >
                          List Of Students
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            <Thead>
                              <Tr>
                                <Th>Student Name</Th>
                                <Th>Student Phone</Th>
                                {/*<Th>Class Pics</Th>*/}
                                {loginStatus.user.staff_type.toLowerCase() != 'teacher' &&
                                  <Th>Actions</Th>
                                }
                              </Tr>
                            </Thead>
                            <Tbody>
                              {filteredUsers.map(users => (
                                <>
                                  {users.className === item.className ? (
                                    <Tr key={users.id}>
                                      <Td  style={{ textAlign: 'start' }}>
                                        {users.first_name} {users.last_name}
                                      </Td>
                                      <Td  style={{ textAlign: 'start' }}>{users.phone}</Td>
                                      {loginStatus.user.staff_type.toLowerCase() != 'teacher' &&
                                        <Td>
                                          <Button
                                            variant='contained'
                                            className='btn'
                                            onClick={() => handleOpenUpdate(users)}
                                          >
                                            Update
                                          </Button>
                                          <Button
                                            variant='contained'
                                            color='error'
                                            className='btn'
                                            onClick={() =>
                                              handleDeleteProductModal(users.id)
                                            }
                                          >
                                            Delete
                                          </Button>
                                          <IDCardGenerator studentData={users} schoolName={loginStatus.admin.school_name} />
                                        </Td>
                                      }
                                    </Tr>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </Tbody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    ))}


                  {loginStatus.user.staff_type.toLowerCase() != 'teacher' && <>

                    {/* delete modal */}
                    <Modal
                      fullscreen
                      open={deleteProductOpen}
                      onClose={() => setDeleteProductOpen(false)}
                      aria-labelledby='modal-modal-title'
                      aria-describedby='modal-modal-description'
                    >
                      <Box sx={{ ...ImgStyle, position: 'relative' }}>
                        <CloseIcon
                          onClick={() => setDeleteProductOpen(false)}
                          style={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            cursor: 'pointer'
                          }}
                        />
                        <h3>Are you Sure You want to delete this Class</h3>
                        <Button
                          variant='contained'
                          className='btn'
                          onClick={() => {
                            handleDeleteProduct(selectedID)
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant='contained'
                          className='btn'
                          onClick={() => setDeleteProductOpen(false)}
                        >
                          No
                        </Button>
                      </Box>
                    </Modal>
                  </>}

                  <StudentForm
                    open={openForm}
                    onClose={() => setOpenForm(false)}
                    onSubmit={handleStudentSubmit}
                    mode={formMode}
                    studentData={studentData}
                    setStudentData={setStudentData}
                  />
                </div>
              ) : (
                <div className='task-cover'>
                  {filteredUsers.map(item => (
                    <>
                      <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                      <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                        <ListItem alignItems='flex-start'>
                          <ListItemText
                            primary={item.taskHeading}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component='span'
                                  variant='body2'
                                  sx={{
                                    color: 'text.primary',
                                    display: 'inline'
                                  }}
                                >
                                  {item.taskDescription}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        {item.amount > 0 ? (
                          <Button component='a' href={item.paymentLink}>
                            Pay Rs. {item.amount}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </List>
                      <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                    </>
                  ))}
                </div>
              )}
            </Box>
          )}
        </Container>

      </ThemeProvider>
    </div >
  )
}

export default StudentDetails
