import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Drawer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Container,
  ThemeProvider,
  createTheme,
  IconButton,
} from "@mui/material";
import { GetStudentResultApi, ListExamApi } from "../../services/apis";
import { useAuth } from "../../services/authContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StudentResultDetails = () => {
  const { loginStatus } = useAuth();
  const [examList, setExamList] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [resultDetails, setResultDetails] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Fetch list of exams
  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await ListExamApi({ student_id: loginStatus.user.id, id: loginStatus.user.id });
        setExamList(response);
      } catch (error) {
        console.error("Error fetching exams:", error);
      }
    };

    fetchExams();
  }, [loginStatus.user.id]);

  // Add this function to aggregate results
  const aggregateResults = (results) => {
    if (results.length === 0) return [];

    // Use the first result as base since exam_name, total_marks, and student_name are same
    const aggregatedResult = {
      ...results[0],
      subject_marks: [],
    };

    // Combine subject_marks from all results
    results.forEach((result) => {
      aggregatedResult.subject_marks.push(...result.subject_marks);
    });

    return [aggregatedResult]; // Return as array to maintain compatibility
  };

  // Modify the fetchResultDetails function
  const fetchResultDetails = async (examId) => {
    try {
      const response = await GetStudentResultApi({
        student_id: loginStatus.user.id,
        exam_id: examId,
      });
      setResultDetails(aggregateResults(response));
    } catch (error) {
      console.error("Error fetching result details:", error);
    }
  };

  const handleViewResult = (exam) => {
    setSelectedExam(exam);
    fetchResultDetails(exam.id);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedExam(null);
    setResultDetails([]);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#284d84", // blue color
      },
      secondary: {
        main: "#EC4899", // Pink color for the check icons
      },
    },
    typography: {
      fontFamily: "Arial, sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Exam Results
        </Typography>

        {/* List of Exams */}
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", gap: 2 }}>
          {examList.map((exam, index) => (
            <Card
              key={index}
              sx={{
                width: 300,
                backgroundColor: "#e1f5fe",
                borderLeft: "6px solid #0288d1",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}>
              <CardContent>
                <Typography variant="h6" gutterBottom color="#0288d1">
                  {exam.exam}
                </Typography>
                <Button variant="contained" sx={{ mt: 2, backgroundColor: "#0288d1" }} onClick={() => handleViewResult(exam)}>
                  View Result
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Drawer for Result Details */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleCloseDrawer}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%", // Makes the drawer full screen
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <Container>
            {/* Fixed Header */}
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1100, // Ensure it stays above other elements
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #ccc",
                backgroundColor: "#0d2238",
                color: "#ffffff",
                padding: "16px",
              }}>
              <IconButton onClick={handleCloseDrawer}>
                <ArrowBackIcon sx={{ color: "#ffffff" }} />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  flexGrow: 1,
                  textAlign: "center",
                }}>
                View Result
              </Typography>
              <Box sx={{ width: 48 }} /> {/* Spacer to balance header layout */}
            </Box>

            {/* Content with Padding */}
            <Box
              sx={{
                flex: 1, // Allows content to fill the remaining space
                overflow: "auto",
                paddingTop: "80px", // Space for the fixed header
                paddingBottom: "80px", // Space for the fixed footer
              }}
              role="presentation">
              {selectedExam && resultDetails.length > 0 && (
                <>
                  <Box sx={{ backgroundColor: "#2e3b55", mb: 2 }}>
                    <Box sx={{ backgroundColor: "#fff" }}>
                      <Table
                        sx={{
                          p: 0,
                          width: "100%",
                          fontSize: "0.9rem",
                          "& td": {
                            p: "4px 8px",
                            fontSize: "0.9rem",
                          },
                          "& .label": {
                            fontWeight: "bold",
                            color: "#555",
                          },
                          "& .value": {
                            fontWeight: "normal",
                            color: "#000",
                            textAlign: "left",
                          },
                        }}>
                        <TableBody>
                          <TableRow sx={{ backgroundColor: "#fff" }}>
                            <TableCell className="label">Exam:</TableCell>
                            <TableCell className="value">{selectedExam.exam}</TableCell>
                            <TableCell className="label">Student:</TableCell>
                            <TableCell className="value">{resultDetails[0].student_name}</TableCell>
                          </TableRow>
                          <TableRow sx={{ backgroundColor: "#fff" }}>
                            <TableCell className="label">Total Marks:</TableCell>
                            <TableCell className="value">{resultDetails[0].total_marks}</TableCell>
                            <TableCell className="label"></TableCell>
                            <TableCell className="value"></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#2e3b55" }}>
                          <TableCell
                            sx={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "0.9rem",
                              p: 1,
                              textAlign: "start",
                            }}>
                            Subject
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "0.9rem",
                              p: 1,
                              textAlign: "center",
                              width: "80px",
                            }}>
                            Theory
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "0.9rem",
                              p: 1,
                              textAlign: "center",
                              width: "80px",
                            }}>
                            Practical
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "0.9rem",
                              p: 1,
                              textAlign: "center",
                              width: "80px",
                            }}>
                            Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resultDetails[0].subject_marks.map((subject, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                              "&:hover": {
                                backgroundColor: "#f1f1f1",
                              },
                            }}>
                            <TableCell
                              sx={{
                                py: 1,
                                px: 1,
                                border: "1px solid #ddd",
                                verticalAlign: "middle",
                              }}>
                              {subject.subject_name}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid #ddd",
                                textAlign: "center",
                              }}>
                              {subject.theory || "0"}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid #ddd",
                                textAlign: "center",
                              }}>
                              {subject.practical || "0"}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid #ddd",
                                textAlign: "center",
                              }}>
                              {parseFloat(subject.theory || 0) + parseFloat(subject.practical || 0)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Box>
          </Container>
        </Drawer>
      </Container>
    </ThemeProvider>
  );
};

export default StudentResultDetails;
