import './App.css'
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './components/Home/Home'
import Layout from './components/Layout/Layout'
import Admin from './components/Admin/Admin'
import Settings from './components/Settings/Settings'
import Terms from './components/Terms/Terms'
import Privacy from './components/Privacy/Privacy'
import CourseManagement from './components/CourseManagement/CourseManagement'
import SubjectManagement from './components/SubjectManagement/SubjectManagement'
import TransportManagement from './components/TransportManagement/TransportManagement'
import TeacherManagement from './components/TeacherManagement/TeacherManagement'
import TimeTableManagement from './components/TimeTableManagement/TimeTableManagement'
import TeacherTimeTable from './components/TeacherTimeTable/TeacherTimeTable'
import TeacherAttendance from './components/TeacherAttendance/TeacherAttendance'
import StudentAttendance from './components/StudentAttendance/StudentAttendance'
import StudentManagement from './components/StudentManagement/StudentManagement'
import StudentHomework from './components/StudentHomework/StudentHomework'
import FeesManagement from './components/FeesManagement/FeesManagement'
import NoticeManagement from './components/NoticeManagement/NoticeManagement'
import GalleryManagement from './components/GalleryManagement/GalleryManagement'
import ExaminationManagement from './components/ExaminationManagement/ExaminationManagement'
import CreateExaminationManagement from './components/CreateExaminationManagement/CreateExaminationManagement'
import CreateResultManagement from './components/CreateResultManagement/CreateResultmanagement'
import VideoManagement from './components/VideoManagemnent/VideoManagemnent'
import StudymaterialManagement from './components/StudymaterialManagemnent/StudymaterialManagemnent'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import LayoutWithBackButton from './components/Utility/LayoutWithBackButton'
import TopImages from './components/TopImages/TopImages'
import UserAttendance from './components/StudentSection/Attendance'
import StudentResultDetails from './components/StudentSection/ViewResult'
import StudentHomeworkView from './components/StudentSection/StudentHomeWorkView'
import ViewTimeTable from './components/StudentSection/ViewTimeTable'
import StudentPersonalDetails from './components/StudentSection/Details'

const theme = createTheme({
  typography: {
    fontFamily: 'CustomFont, Arial, sans-serif', // Fallback fonts included
    h1: { fontWeight: 700 }, // Bold
    h2: { fontWeight: 600 }, // SemiBold
    body1: { fontWeight: 400 }, // Regular
    body2: { fontWeight: 300 }, // Light
    italic: { fontWeight: 400, fontStyle: 'italic' } // Italic
  }
})

const routeConfig = [
  {
    path: '/examinationManagement',
    component: <ExaminationManagement />,
    layoutProps: { title: 'Examination Management' },
  },
  {
    path: '/createxaminationmanagement',
    component: <CreateExaminationManagement />,
    layoutProps: { title: 'Create Examination', to: '/examinationManagement' },
  },
  {
    path: '/createresultmanagement',
    component: <CreateResultManagement />,
    layoutProps: { title: 'Create Result', to: '/examinationManagement' },
  },

  {
    path: '/videoManagement',
    component: <VideoManagement />,
    layoutProps: { title: 'Manage Videos', to: '/' },
  },
  {
    path: '/galleryManagement',
    component: <GalleryManagement />,
    layoutProps: { title: 'Gallery', to: '/' },
  },
  {
    path: '/studymaterialManagement',
    component: <StudymaterialManagement />,
    layoutProps: { title: 'Study Material Management' },
  },
  {
    path: '/student',
    component: <StudentManagement />,
    layoutProps: { title: 'Student Management', to: '/' },
  },
  {
    path: '/studentAttendance',
    component: <StudentAttendance />,
    layoutProps: { title: 'Attendance', to: '/' },
  },
  {
    path: '/studentHomework',
    component: <StudentHomework />,
    layoutProps: { title: 'Homework', to: '/' },
  },
  {
    path: '/noticeManagement',
    component: <NoticeManagement />,
    layoutProps: { title: 'Notices', to: '/' },
  },
  {
    path: '/add_user',
    component: <Admin />,
    layoutProps: { title: 'Add User' }
  },
  {
    path: '/topImages',
    component: <TopImages />,
    layoutProps: { title: 'Top Images' }
  },
  {
    path: '/settings',
    component: <Settings />,
    layoutProps: { title: 'Settings' }
  },
  {
    path: '/terms',
    component: <Terms />,
    layoutProps: { title: 'Terms' }
  },
  {
    path: '/privacy',
    component: <Privacy />,
    layoutProps: { title: 'Privacy' }
  },
  {
    path: '/class',
    component: <CourseManagement />,
    layoutProps: { title: 'Courses' }
  },
  {
    path: '/subject',
    component: <SubjectManagement />,
    layoutProps: { title: 'Subjects' }
  },
  {
    path: '/driver',
    component: <TransportManagement />,
    layoutProps: { title: 'Transport' }
  },
  {
    path: '/fees',
    component: <FeesManagement />,
    layoutProps: { title: 'Fees' }
  },
  {
    path: '/teacher',
    component: <TeacherManagement />,
    layoutProps: { title: 'Teachers' }
  },
  {
    path: '/timetable',
    component: <TimeTableManagement />,
    layoutProps: { title: 'Timetable' }
  },
  {
    path: '/teachertimetable',
    component: <TeacherTimeTable />,
    layoutProps: { title: 'Teacher Timetable' }
  },
  {
    path: '/teacherAttendance',
    component: <TeacherAttendance />,
    layoutProps: { title: 'Teacher Attendance' }
  },
  {
    path: '/getStudentAttendance',
    component: <UserAttendance />,
    layoutProps: { title: 'Student Attendance' }
  },
  {
    path: '/viewResult',
    component: <StudentResultDetails />,
    layoutProps: { title: 'Result' }
  },
  {
    path: '/viewHomework',
    component: <StudentHomeworkView />,
    layoutProps: { title: 'HomeWork' }
  },
  {
    path: '/viewTimetable',
    component: <ViewTimeTable />,
    layoutProps: { title: 'TimeTable' }
  },
  {
    path: '/viewDetail',
    component: <StudentPersonalDetails />,
    layoutProps: { title: 'About Me' }
  },
];


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='*' element={<Home />} />
            {/* <Route path='/add_user' element={<Admin />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/class' element={<CourseManagement />} />
            <Route path='/subject' element={<SubjectManagement />} />
            <Route path='/driver' element={<TransportManagement />} />
            <Route path='/fees' element={<FeesManagement />} />
            <Route path='/teacher' element={<TeacherManagement />} />
            <Route path='/timetable' element={<TimeTableManagement />} />
            <Route path='/teachertimetable' element={<TeacherTimeTable />} />
            <Route path='/teacherAttendance' element={<TeacherAttendance />} /> */}
            {/* <Route path='/student' element={<StudentManagement />} />
            <Route path='/studentAttendance' element={<StudentAttendance />} />
            <Route path='/studentHomework' element={<StudentHomework />} />
            <Route path='/noticeManagement' element={<NoticeManagement />} /> */}
            {/* <Route path='/galleryManagement' element={<GalleryManagement />} /> */}

            {routeConfig.map((route) => (
              <Route
                key={route.path}
                element={route.layoutProps ? <LayoutWithBackButton {...route.layoutProps} /> : null}
              >
                <Route path={route.path} element={route.component} />
              </Route>
            ))}

            {/* <Route
              path='/examinationManagement'
              element={<ExaminationManagement />}
            />
            <Route
              path='/createxaminationmanagement'
              element={<CreateExaminationManagement />}
            />
            <Route
              path='/createresultmanagement'
              element={<CreateResultManagement />}
            />
            <Route path='/publishresultManagement' element={<PublishResultManagement />} />
            <Route element={<LayoutWithBackButton />}>
              <Route path='/videoManagement' element={<VideoManagement />} />
            </Route>
            <Route
              path='/studymaterialManagement'
              element={<StudymaterialManagement />}
            /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
