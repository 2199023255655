import React from 'react'
import PropTypes from 'prop-types'
import './VideoManagemnent.scss'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import ArrowBack from '@mui/icons-material/ArrowBack'
import YouTube from 'react-youtube'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Fab,
  Select,
  Accordion,
  MenuItem,
  Card,
  CardMedia,
  CardContent,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  SelectChangeEvent,
  Button,
  useMediaQuery,
  Modal,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  Grid
} from '@mui/material'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import {
  CreateVideoApi,
  ListVideoApi,
  UpdateVideoApi,
  DeleteVideoApi
} from '../../services/apis'
import { BEBase } from '../../constants/app.constant'
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useAuth } from '../../services/authContext'
import { format } from 'date-fns'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import TopBackBar from '../Utility/TopBackBar'
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../componentConst';

import CryptoJS from 'crypto-js'

const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23;

const decryptData = encryptedData => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY)
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decryptedString) // Parse the JSON string
  } catch (error) {
    console.error('Decryption error:', error)
    return null // Return null if decryption fails
  }
}

const VisuallyHiddenInput = {
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#284d84' // blue color
    }
  }
})

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%', // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '190px',
  '@media (max-width: 600px)': {
    // Adjust the breakpoint as needed
    width: '100%' // Width for mobile devices
  }
}

const VideoManagemnent = () => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [day, setDay] = React.useState('thursday')
  const [value, setValue] = React.useState(2)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = React.useState(false)
  const [recipient, setRecipient] = React.useState('ALL')
  const [title, setTitle] = React.useState('')
  const [Video, setVideo] = React.useState('')
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState('')
  const [heading, setHeading] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [amount, setAmount] = React.useState('')
  const [paymentLink, setPaymentLink] = React.useState('')
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [files, setFiles] = React.useState(null) // State to store uploaded files
  const [updateOpen, setUpdateOpen] = React.useState(false)
  const [selectedVideo, setSelectedVideo] = React.useState(null)
  const [Videos, setVideos] = React.useState([])
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false)
  const [selectedID, setSelectedID] = React.useState('')

  const handleFileChange = event => {
    setFiles(event.target.files)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  React.useEffect(() => {
    if (listApiFlag && loginStatus.user) {
      const fetchListProducts = async () => {
        try {
          const response = await ListVideoApi({
            id: JSON.parse(loginStatus.user.id)
          })
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
    }
  }, [loginStatus])

  const filteredUsers = listOfUsers.filter(user =>
    user.topic.toLowerCase().includes(filter.toLowerCase())
  )

  const handleAccordianAction = async (action, item) => {
    if (action.toLowerCase() === 'delete') {
      setDeleteProductOpen(true)
      setSelectedID(item.id)
    }
  }

  const handleDeleteProduct = async deleteId => {
    try {
      const formData = new FormData()
      formData.append('id', selectedID)

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteVideoApi(formData)

      console.log('Product deleted successfully:', response)
      handleClose()
      window.location.reload()
    } catch (error) {
      console.error('Error deleting Product:', error)
    }
  }

  const handleUpdateOpen = Video => {
    setSelectedID(Video.id)
    setTitle(Video.topic)
    setVideo(Video.video_link)
    setUpdateOpen(true)
  }

  const handleUpdateClose = () => {
    setUpdateOpen(false)
    setRecipient('')
    setTitle('')
    setVideo('')
    setFiles(null)
  }

  const handleUpdateSubmit = async event => {
    event.preventDefault()
    try {
      const encryptedCreds = localStorage.getItem('loginCred')

      if (!encryptedCreds) {
        throw new Error('No login credentials found in localStorage')
      }

      const decryptedCreds = decryptData(encryptedCreds)

      if (!decryptedCreds || !decryptedCreds.token) {
        throw new Error(
          'Failed to retrieve valid token from decrypted credentials'
        )
      }

      const authToken = JSON.stringify(decryptedCreds)
      const formData = {
        video_link: Video,
        topic: title,
        id: selectedID
      }

      const response = await UpdateVideoApi(formData)
      console.log('Product created successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating Product:', error)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const url = BEBase + 'list_users/'
      const encryptedCreds = localStorage.getItem('loginCred')

      if (!encryptedCreds) {
        throw new Error('No login credentials found in localStorage')
      }

      const decryptedCreds = decryptData(encryptedCreds)

      if (!decryptedCreds || !decryptedCreds.token) {
        throw new Error(
          'Failed to retrieve valid token from decrypted credentials'
        )
      }

      const authToken = JSON.stringify(decryptedCreds)
      const formData = {
        video_link: Video,
        topic: title,
        id: loginStatus.user.id
      }

      const response = await CreateVideoApi(formData)
      console.log('Product created successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating Product:', error)
    }
  }

  const handleChange = event => {
    setDay(event.target.value)
  }

  const getEmbedUrl = url => {
    const videoId = url.split('v=')[1]?.split('&')[0]
    return videoId
  }

  const opts = {
    width: '100%'
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          flexGrow: 1,
          mt: { xs: 2, md: 5 }, // Margin for small and medium screens
          mb: { xs: 5, md: 7 }, // Adjust bottom margin
          paddingBottom: { xs: '80px', md: '80px' }
          // bgcolor: 'background.paper'
        }}
      >
        <Modal
          open={updateOpen}
          onClose={handleUpdateClose}
          aria-labelledby='update-modal-title'
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isMobile ? 350 : 500,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2
            }}
          >
            <Typography
              id='update-modal-title'
              variant='h6'
              sx={{ mb: 2, color: 'primary.main' }}
            >
              <CloseIcon
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  cursor: 'pointer',
                  color: 'grey.600',
                  '&:hover': {
                    color: 'grey.800'
                  }
                }}
              />
              Update Video
            </Typography>
            <form onSubmit={handleUpdateSubmit}>
              <TextField
                fullWidth
                label='Title'
                variant='outlined'
                value={title}
                onChange={e => setTitle(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label='Enter Video Url'
                variant='outlined'
                value={Video}
                onChange={e => setVideo(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Button type='submit' variant='contained' color='primary'>
                  Update
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Modal
          fullscreen
          open={deleteProductOpen}
          onClose={() => setDeleteProductOpen(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={{ ...ImgStyle, position: 'relative' }}>
            <CloseIcon
              onClick={() => setDeleteProductOpen(false)}
              style={{
                position: 'absolute',
                top: 16,
                right: 16,
                cursor: 'pointer'
              }}
            />
            <h3>Are you Sure You want to delete this Video</h3>
            <Button
              variant='contained'
              className='btn'
              onClick={() => {
                handleDeleteProduct()
              }}
            >
              Yes
            </Button>
            <Button
              variant='contained'
              className='btn'
              onClick={() => setDeleteProductOpen(false)}
            >
              No
            </Button>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isMobile ? 350 : 500,
              height: isMobile ? 350 : 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2
            }}
          >
            <CloseIcon
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                cursor: 'pointer',
                color: 'grey.600',
                '&:hover': {
                  color: 'grey.800'
                }
              }}
            />

            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h2'
              sx={{ mb: 2, color: 'primary.main' }}
            >
              Add Video
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label='Title'
                variant='outlined'
                value={title}
                onChange={e => setTitle(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label='Enter Video'
                variant='outlined'
                value={Video}
                onChange={e => setVideo(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Button type='submit' variant='contained' color='primary'>
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <TextField
          fullWidth
          label='Filter Video'
          id='fullWidth'
          value={filter}
          sx={{ marginBottom: 3, marginTop: 5 }}
          onChange={e => setFilter(e.target.value)}
        />
        {filteredUsers.length === 0 ? (
          <Box sx={{ mx: 'auto' }}>
            <Box
              position='relative'
              elevation={0}
              sx={{
                marginTop: isMobile ? '56px' : '100px',
                maxHeight: '100vh', // Limit the height to the viewport
                overflow: 'auto',
                display: 'flex', // Add scrolling for overflow content
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                variant='h4'
                component='h1'
                gutterBottom
                fontWeight='bold'
                sx={{
                  mt: 2,
                  textAlign: 'center', // Center-align text for better responsiveness
                  mx: 'auto', // Center-align horizontally
                  maxWidth: '90%', // Ensure text doesn't exceed screen width
                  // marginLeft: isMobile ? '180px' : '270px',
                  marginTop: isMobile ? '-10px' : '0px'
                }}
              >
                "Education is the foundation upon which we build our future"
                <br />
                <Box
                  component='span'
                  color='#df7500'
                  sx={{
                    display: 'block', // Ensure it breaks onto a new line
                    textAlign: 'center', // Center the text
                    fontSize: { xs: '14px', sm: '16px', md: '18px' }, // Adjust font size for different screen sizes
                    mt: 2 // Add some space above
                  }}
                >
                  Littlesteps
                </Box>
              </Typography>

          

              {/* <Typography variant="body2" color="text.secondary" sx={{ mt: 2, marginLeft: isMobile ? "56px" : "170px", }}>
                File must be JPEG, JPG or PNG and up to 40MB
              </Typography> */}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 4,
                  mt: 3
                }}
              >
                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CheckCircleIcon color="secondary" fontSize="small" />
                  <Typography variant="body2">Free to use</Typography>
                </Box> */}
                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CheckCircleIcon color="secondary" fontSize="small" />
                  <Typography variant="body2">No credit card required</Typography>
                </Box> */}
              </Box>

              <Typography variant='body2' align='center' sx={{ mt: 2 }}>
                Read the terms and policies carefully{' '}
                <Link href='#' color='primary'>
                  Terms of use
                </Link>{' '}
                and{' '}
                <Link href='#' color='primary'>
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            {filteredUsers
              .filter(
                (item, index, self) =>
                  index === self.findIndex(t => t.title === item.title)
              )
              .map(item => (
                <Grid justifyContent='center'>
                  {filteredUsers.map((video, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                      <Card
                        sx={{
                          position: 'relative',
                          margin: { sm: '0px', lg: '10px' } // Ensure units are explicit
                          // Alternative: Add !important to enforce
                          // margin: { sm: '0px !important', lg: '10px !important' },
                        }}
                      >
                        <YouTube
                          videoId={getEmbedUrl(video.video_link)}
                          opts={opts}
                        />
                        <CardContent
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-around'
                          }}
                        >
                          <Box>
                            <Typography variant='h6'>{video.topic}</Typography>
                          </Box>
                          {loginStatus.user.staff_type !== "student" &&
                            <Box>
                              <IconButton
                                sx={{
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                  color: 'white'
                                }}
                                onClick={() =>
                                  handleAccordianAction('delete', video)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                sx={{
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                  color: 'white'
                                }}
                                onClick={() => handleUpdateOpen(video)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Box>
                          }
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ))}
          </Box>
        )}
      </Container>
      {loginStatus.user.staff_type !== "student" &&
        <Fab
          color='primary'
          aria-label='add'
          onClick={handleOpen}
          sx={{
            marginBottom: '55px',
            marginRight: '20px',
            position: 'fixed',
            bottom: 16,
            right: 4,
            zIndex: 1000
          }}
        >
          {/* Add your icon here, for example: */}
          <AddIcon />
        </Fab>
      }
      {/* </Box> */}
    </ThemeProvider>
  )
}

// const VideoManagemnent = () => (
//   <div className="VideoManagemnent" data-testid="VideoManagemnent">
//     VideoManagemnent Component
//   </div>
// );

VideoManagemnent.propTypes = {}

VideoManagemnent.defaultProps = {}

export default VideoManagemnent
