import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../services/authContext';
import { Container, Box, Fab , TextField, Button, Modal, Typography, Divider, List, ListItem, Autocomplete, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ListTransportApi, ListProductApi,  CreateTaskApi, ListTaskApi, CreateTransportApi , DeleteTransportApi, UpdateCourseImageApi, UpdateTransportApi } from '../../services/apis';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import { BEBase } from '../../constants/app.constant';
import './TransportManagement.scss';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' },
    // Add more theme customizations as needed
  },
});

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vW",
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '190px'
};

const UpdateStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vW",
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '190px'
};

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%", // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '190px',
  '@media (max-width: 600px)': { // Adjust the breakpoint as needed
    width: '100%', // Width for mobile devices
  }
};

const TransportManagement = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [heading, setHeading] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [paymentLink, setPaymentLink] = React.useState("");
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [className, setclassName] = React.useState("");
  const [classList, setCLassList] = React.useState([]);
  const [transportName, settransportNamen] = React.useState("");
  const [transportCode, settransportCode] = React.useState("");
  const [transportType, settransportType] = React.useState("");
  const [discountedPrice, setDiscountedPrice] = React.useState("");
  const [productPic1, setProductPic1] = React.useState();
  const [productPic2, setProductPic2] = React.useState();
  const [productPic3, setProductPic3] = React.useState();
  const [productPic4, setProductPic4] = React.useState();
  const [productPic5, setProductPic5] = React.useState();
  const [imgOpen, setImgOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState("")
  const [openedImage, setOpenedImage] = React.useState("");
  const [syllabus, setSyllabus] = React.useState();
  const [changeImgFlag, setChangeImgFlag] = React.useState("");
  const [imageCacheKey, setImageCacheKey] = React.useState(Date.now());

  React.useEffect(() => {

    if (listApiFlag && loginStatus.user && loginStatus.user.staff_type === "admin") {
      const fetchListProducts = async () => {
        try {
          const response = await ListTransportApi();
          setListOfUsers(response);
          setListApiFlag(false);
        } catch (error) {
          console.error("Error fetching users:", error);
          setListApiFlag(false);
        }
      };

      const fetchListClasses = async () => {
        try {
          const response = await ListProductApi();
          response.forEach(element => {
            element.title = element.productName
          });
          
          setCLassList(response);
          setListApiFlag(false);
        } catch (error) {
          console.error("Error fetching users:", error);
          setListApiFlag(false);
        }
      };

      fetchListProducts();
      fetchListClasses();
    }

  }, [loginStatus]);

  const handleUpdateTask = async () => {
    try {
      const formData = new FormData();
      formData.append('id', selectedID);
      formData.append('className', className);
      formData.append('transportName', transportName);
      formData.append('transportCode', transportCode);
      formData.append('transportType', transportType);
      formData.append('syllabus', syllabus);
  
      // Log form data
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
  
      const response = await UpdateTransportApi(formData);
      console.log("Product created successfully:", response);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Error creating Product:", error);
    }
  }

  const filteredUsers = listOfUsers.filter(user =>
    user.transportName.toLowerCase().includes(filter.toLowerCase())
  );

  const [open, setOpen] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpen = (username) => {
    setSelectedUser(username);
    setclassName(username.className);
    settransportNamen(username.transportName);
    settransportCode(username.transportCode);
    settransportType(username.transportType);
    setSelectedID(username.id);
    setSyllabus(username.syllabus);
    setOpen(true);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleImageOpen = (path) => {
    setOpenedImage(path);
    setImgOpen(true);
    setImageCacheKey(Date.now());
  };
  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
  }

  const handleCreateProduct = async () => {
    try {
      const formData = new FormData();
      formData.append('className', className);
      formData.append('transportName', transportName);
      formData.append('transportCode', transportCode);
      formData.append('transportType', transportType);
      formData.append('syllabus', syllabus);
  
      // Log form data
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
  
      const response = await CreateTransportApi(formData);
      console.log("Product created successfully:", response);
      window.location.reload();
      handleClose();
    } catch (error) {
      console.error("Error creating Product:", error);
    }
  };

  const handleUpdateImageTask = async (imagePath) => {
      console.log(imagePath);
      const formData = new FormData();
      formData.append('changeImgFlag', changeImgFlag)
      formData.append('imagePath', imagePath)
      var response = await UpdateCourseImageApi(formData)
  }

  const handleDeleteProduct = async (deleteId) => {
    try {
      const formData = new FormData();
      formData.append('id', deleteId);
  
      // Assuming DeleteCourseApi expects formData
      const response = await DeleteTransportApi(formData);
      
      console.log("Product deleted successfully:", response);
      handleClose();
    } catch (error) {
      console.error("Error deleting Product:", error);
    }
  };

  return (
    <div className="TransportManagement" data-testid="TransportManagement">
      <ThemeProvider theme={theme}>
        <Box className="CourseManagement" data-testid="CourseManagement" sx={{ padding: '90px 0px', display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
          {loginStatus.token && (
            <Box>
              {loginStatus.user.staff_type === "admin" ? (
                <div className='table-cover'>
                  <Box sx={{ position: 'absolute', bottom: 76, right: 16 }}>
                  <Fab color="primary" aria-label="add" onClick={handleOpenCreate} sx={{position: "fixed", right: "30px", bottom: "70px"}}>
                    <AddIcon />
                  </Fab>
                  </Box>
                  <TextField
                    fullWidth
                    label="Filter Class"
                    id="fullWidth"
                    value={filter}
                    sx={{ marginBottom: 3 }}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Class Name</Th>
                        <Th>Transport Name</Th>
                        <Th>Transport Type</Th>
                        <Th>Transport Code</Th>
                        {/*<Th>Class Pics</Th>*/}
                        <Th>Actions</Th>
                        <Th>Details</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredUsers.map((item) => (
                        <Tr key={item.id}>
                          <Td>{item.className}</Td>
                          <Td>{item.transportName}</Td>
                          <Td>{item.transportType}</Td>
                          <Td>{item.transportCode}</Td>
                          {/*<Td>
                            <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(item.productPic1)}>1</Button>
                            <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(item.productPic2)}>2</Button>
                            <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(item.productPic3)}>3</Button>
                            <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(item.productPic4)}>4</Button>
                            <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(item.productPic5)}>5</Button>
                          </Td>*/}
                          <Td><Button variant="contained" className='btn' onClick={() => handleOpen(item)}>Update</Button>
                          <Button variant="contained" color="error" className='btn' onClick={() => handleDeleteProduct(item.id)}>Delete</Button></Td>
                          <Td>
                          <Button component="a" variant="contained" color="success" href={BEBase + "media/" + item.syllabus + `?_=${new Date().getTime()}`} target="_blank">
                            Check
                          </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <Modal
fullscreen
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{ ...UpdateStyle, position: 'relative' }}>
                      <CloseIcon
                        onClick={handleClose}
                        style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                      />
                      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 3 }}>
                        Update Task
                      </Typography>

                      <Autocomplete
                        id="free-solo-demo-class"
                        options={classList.map((option) => option.title)}
                        value={className}
                        required
                        onChange={(event, newValue) => setclassName(newValue)}
                        renderInput={(params) => <TextField fullWidth {...params} label="Select Class" required error={!className} />}
                        sx={{ marginBottom: 3 }}
                      />
                      <TextField
                        fullWidth
                        required
                        label="Transport Name"
                        className='register-form'
                        value={transportName}
                        onChange={(e) => settransportNamen(e.target.value)}
                        sx={{ marginBottom: 3 }}
                        error={!transportName}
                      />
                      <TextField
                        fullWidth
                        required
                        label="Transport Code"
                        className='register-form'
                        value={transportCode}
                        onChange={(e) => settransportCode(e.target.value)}
                        sx={{ marginBottom: 3 }}
                        error={!transportCode}
                      />
                      <Autocomplete
                        id="free-solo-demo-type"
                        value={transportType}
                        options={[{ title: "Mandatory" }, { title: "Optional" }, { title: "Other" }].map((option) => option.title)}
                        onChange={(event, newValue) => settransportType(newValue)}
                        renderInput={(params) => <TextField fullWidth {...params} label="Select Transport Type" required error={!transportType} />}
                        sx={{ marginBottom: 3 }}
                      />
                      <label>Syllabus</label>
                      <TextField
                        fullWidth
                        required
                        className='register-form'
                        accept=".pdf"
                        type='file'
                        onChange={(e) => setSyllabus(e.target.files[0])}
                        error={!syllabus}
                      />
                      
                      <Button
                        variant="contained"
                        className='btn'
                        onClick={handleUpdateTask}
                        disabled={!className || !transportName || !transportCode || !transportType || !syllabus}
                      >
                        Update Transport
                      </Button>
                    </Box>

                  </Modal>
                  <Modal
fullscreen
                    open={imgOpen}
                    onClose={() => setImgOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{ ...ImgStyle, position: 'relative' }}>
                      <CloseIcon
                        onClick={() => setImgOpen(false)}
                        style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                      />
                      <Box>
                        <img src={`${BEBase}media/${openedImage}?cache=${imageCacheKey}`} style={{ width: "100%" }} />
                      </Box>
                      <TextField
                        fullWidth
                        required
                        className='register-form'
                        type='file'
                        sx={{ marginBottom: 3 }}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            setChangeImgFlag(file);
                          }
                        }}
                      />
                      <Button variant="contained" className='btn' onClick={() => {
                        handleUpdateImageTask(openedImage);
                        setImgOpen(false); // Close the modal after the update
                      }}>Change Image</Button>
                    </Box>
                  </Modal>
                  <Modal
fullscreen
                    open={openCreate}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{overflow: 'auto'}}
                  >
                    <Box sx={{ ...style, position: 'relative' }}>
                      <CloseIcon
                        onClick={handleClose}
                        style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                      />
                      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 3 }}>
                        Create New Transport
                      </Typography>
                      <Autocomplete
                        id="free-solo-demo"
                        options={classList.map((option) => option.title)}
                        onChange={(event, newValue) => setclassName(newValue)}
                        renderInput={(params) => <TextField fullWidth {...params} label="Select Class" />}
                        sx={{ marginBottom: 3 }}
                      />
                      <TextField
                        fullWidth
                        required
                        label="Transport Name"
                        className='register-form'
                        value={transportName}
                        onChange={(e) => settransportNamen(e.target.value)}
                        sx={{ marginBottom: 3 }}
                      />
                      <TextField
                        fullWidth
                        required
                        label="Transport Code"
                        className='register-form'
                        value={transportCode}
                        onChange={(e) => settransportCode(e.target.value)}
                        sx={{ marginBottom: 3 }}
                      />
                       <Autocomplete
                        id="free-solo-demo"
                        options={[{title : "Mandatory"}, {title: "Optional"}, {title: "Other"}].map((option) => option.title)}
                        onChange={(event, newValue) => settransportType(newValue)}
                        renderInput={(params) => <TextField fullWidth {...params} label="Select Class" />}
                        sx={{ marginBottom: 3 }}
                      />
                      {/*<label>Product Pic 1</label>
                      <TextField 
                        fullWidth
                        required
                        className='register-form'
                        type='file'
                        sx={{ marginBottom: 3 }}
                        onChange={(e) => setProductPic1(e.target.files[0])} />
                      
                      <label>Product Pic 2</label>
                      <TextField 
                        fullWidth
                        required
                        className='register-form'
                        type='file'
                        sx={{ marginBottom: 3 }}
                        onChange={(e) => setProductPic2(e.target.files[0])} />

                      <label>Product Pic 3</label>
                      <TextField 
                        fullWidth
                        required
                        className='register-form'
                        type='file'
                        sx={{ marginBottom: 3 }}
                        onChange={(e) => setProductPic3(e.target.files[0])} />
                      
                      <label>Product Pic 4</label>
                      <TextField 
                        fullWidth
                        required
                        className='register-form'
                        type='file'
                        sx={{ marginBottom: 3 }}
                        onChange={(e) => setProductPic4(e.target.files[0])} />
                      
                      <label>Product Pic 5</label>
                      <TextField 
                        fullWidth
                        required
                        className='register-form'
                        type='file'
                        onChange={(e) => setProductPic5(e.target.files[0])} />*/}
                      <label>Syllabus</label>
                      <TextField 
                        fullWidth
                        required
                        className='register-form'
                        type='file'
                        onChange={(e) => setSyllabus(e.target.files[0])} />

                      <Button variant="contained" className='btn' onClick={handleCreateProduct}  disabled={!className || !transportName || !transportCode || !transportType || !syllabus}>Create Transport</Button>
                    </Box>
                  </Modal>
                  
                </div>
              ) : (
                <div className="task-cover">
                  {filteredUsers.map((item) => (
                    <>
                      <Divider variant='inset' sx={{ marginLeft: "0px" }} />
                      <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            primary={item.taskHeading}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{ color: 'text.primary', display: 'inline' }}
                                >
                                  {item.taskDescription}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        {item.amount > 0 ?
                        <Button component="a" href={item.paymentLink}>
                          Pay Rs. {item.amount}
                        </Button>
                        :
                        <></>
                        }
                      </List>
                      <Divider variant='inset' sx={{ marginLeft: "0px" }} />
                    </>
                  )
                  )}
                </div>
              )}
                </Box>
              )}
          </Container>
        </Box>
      </ThemeProvider>
    </div>
  )
};

TransportManagement.propTypes = {};

TransportManagement.defaultProps = {};

export default TransportManagement;
