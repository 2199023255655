import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './TimeTableDetails.scss'
import { createTheme } from '@mui/material/styles'
import {
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  ListItemText,
  TableHead,
  TableRow,
  Paper,
  List,
  TextField,
  Box,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  SwipeableDrawer
} from '@mui/material'
import {
  ListClassRelatedToSectionApi,
  UpdateTimeTableApi,
  ListTeachersApi
} from '../../services/apis'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { MobileTimePicker } from '@mui/x-date-pickers'
import CryptoJS from 'crypto-js';
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../componentConst';


const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23; // Replace with a secure key

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString); // Parse the JSON string
  } catch (error) {
    console.error('Decryption error:', error);
    return null; // Return null if decryption fails
  }
};


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
  }
})

const TimeTableDetails = props => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [drawerOpenTeacher, setDrawerOpenTeacher] = React.useState(false)
  const [timetableData, setTimetableData] = React.useState([])
  const [currentSelection, setCurrentSelection] = React.useState({
    rowIndex: null,
    day: null,
    period: null
  })
  const [currentSelectionTeacher, setCurrentSelectionTeacher] = React.useState({
    rowIndex: null,
    day: null,
    period: null
  })
  const [addedClassFromSection, setAddedClassFromSection] = React.useState([])
  const [selectedClasses, setSelectedClasses] = React.useState({}) // Stores selected subjects
  const [startTimes, setStartTimes] = React.useState({}) // Stores selected start times
  const [endTimes, setEndTimes] = React.useState({}) // Stores selected end times
  const [teacher, setTeacher] = React.useState([]) // Stores all teachers
  const [selectedTeachers, setSelectedTeachers] = React.useState({}) // Stores selected teachers
  const [selectedTeachersId, setSelectedTeachersId] = React.useState({})
  const [periods, setPeriods] = React.useState(
    Object.keys(props.timetableData[0].monday || {}) // Safeguard against undefined props.monday
  ) // Define periods dynamically
  const tableContainerRef = React.useRef(null)

  React.useEffect(() => {
    if (Array.isArray(props.timetableData)) {
      setTimetableData(props.timetableData)
      console.log('myttdata', props.timetableData)

      // Initialize selectedClasses, startTimes, endTimes from props.timetableData
      const initialSelectedClasses = {}
      const initialSelectedTeachers = {} // Initialize selectedTeachers here
      const initialStartTimes = {}
      const initialEndTimes = {}

      props.timetableData.forEach((row, rowIndex) => {
        const daysOfWeek = [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday'
        ]
        daysOfWeek.forEach(day => {
          const dayData =
            typeof row[day] === 'string' ? JSON.parse(row[day]) : row[day]
          if (dayData) {
            periods.forEach(period => {
              // Prepopulate selectedClasses, selectedTeachers, startTimes, endTimes
              initialSelectedClasses[rowIndex] =
                initialSelectedClasses[rowIndex] || {}
              initialSelectedClasses[rowIndex][day] =
                initialSelectedClasses[rowIndex][day] || {}
              initialSelectedClasses[rowIndex][day][period] =
                dayData[period]?.subject || ''

              initialSelectedTeachers[rowIndex] =
                initialSelectedTeachers[rowIndex] || {}
              initialSelectedTeachers[rowIndex][day] =
                initialSelectedTeachers[rowIndex][day] || {}
              initialSelectedTeachers[rowIndex][day][period] =
                dayData[period]?.teacher || '' // Prepopulate teacher

              initialStartTimes[`${rowIndex}-${period}`] =
                dayData[period]?.startTime || ''
              initialEndTimes[`${rowIndex}-${period}`] =
                dayData[period]?.endTime || ''
            })
          }
        })
      })

      setSelectedClasses(initialSelectedClasses)
      setSelectedTeachers(initialSelectedTeachers) // Set selected teachers
      setStartTimes(initialStartTimes)
      setEndTimes(initialEndTimes)

      // Fetch classes and teachers
      const subjectIds = props.timetableData
        .flatMap(row =>
          [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday'
          ].map(day => {
            const dayData =
              typeof row[day] === 'string' ? JSON.parse(row[day]) : row[day]
            return Object.values(dayData).map(
              periodData => periodData.subject_id
            )
          })
        )
        .flat()

      const classFromSectionsApi = async sectionId => {
        const body = { id: sectionId }
        try {
          const classFromSection = await ListClassRelatedToSectionApi(body)
          const filteredClasses = classFromSection.filter(classData =>
            subjectIds.includes(classData.subject_id)
          )
          setAddedClassFromSection(classFromSection)
        } catch (error) {
          console.error('Error fetching class data:', error)
        }
      }

      const getTeachersApi = async () => {
        try {
          const encryptedCreds = localStorage.getItem('loginCred')

          if (!encryptedCreds) {
            throw new Error('No login credentials found in localStorage')
          }

          const decryptedCreds = decryptData(encryptedCreds)

          if (!decryptedCreds || !decryptedCreds.token) {
            throw new Error(
              'Failed to retrieve valid token from decrypted credentials'
            )
          }

          const authToken = JSON.stringify(decryptedCreds)
          const classFromSection = await ListTeachersApi(
            JSON.stringify({ adminToken: JSON.parse(authToken).token })
          )
          console.log('Teachers:', classFromSection)
          setTeacher(classFromSection) // Update teachers list
        } catch (error) {
          console.error('Error fetching teacher data:', error)
        }
      }

      if (props.timetableData && props.timetableData.length > 0) {
        classFromSectionsApi(props.timetableData[0].section_id)
        getTeachersApi()
      }
    }
  }, [props.timetableData])

  const subjects = addedClassFromSection?.[0]?.subject || []

  const handleAddMorePeriods = () => {
    setPeriods((periods) => [...periods, `p${periods.length + 1}`]);
    setTimeout(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
      }
    }, 100);
  };
  

  const handleClassChange = (rowIndex, day, period, selectedSubject) => {
    setSelectedClasses(prevState => {
      const updatedRow = { ...prevState[rowIndex] } // Clone the current row
      if (!updatedRow[day]) {
        updatedRow[day] = {} // Initialize the day if it doesn't exist
      }
      updatedRow[day][period] = {
        subjectName: selectedSubject?.subjectName || '', // Save subjectName
        subjectId: selectedSubject?.id || '' // Save subjectId
      }
      return { ...prevState, [rowIndex]: updatedRow } // Return updated state
    })
  }

  const handleTeacherChange = (rowIndex, day, period, selectedSubject) => {
    setSelectedTeachers(prevState => {
      const updatedRow = { ...prevState[rowIndex] } // Clone the current row
      if (!updatedRow[day]) {
        updatedRow[day] = {} // Initialize the day if it doesn't exist
      }
      updatedRow[day][period] = {
        teacher:
          selectedSubject?.first_name + ' ' + selectedSubject?.last_name || '', // Save subjectName
        teacherId: selectedSubject?.id || '' // Save subjectId
      }
      return { ...prevState, [rowIndex]: updatedRow } // Return updated state
    })
  }

  const formatTimetableData = () => {
    return timetableData.map((row, rowIndex) => {
      const rowData = {
        id: row.id,
        className: row.className
      }

      const daysOfWeek = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
      ]
      daysOfWeek.forEach(day => {
        const dayData = {}
        periods.forEach(period => {
          dayData[period] = {
            startTime: startTimes[`${rowIndex}-${period}`] || '',
            endTime: endTimes[`${rowIndex}-${period}`] || '',
            subject: selectedClasses[rowIndex]?.[day]?.[period] || '',
            teacher: selectedTeachers[rowIndex]?.[day]?.[period] || ''
          }
        })

        rowData[day] = JSON.stringify(dayData) // Store as stringified JSON
      })

      return rowData
    })
  }

  const handleTimeChange = (rowIndex, period, type, newTime) => {
    if (newTime && newTime.isValid()) {
      const formattedTime = newTime.format('HH:mm')
      if (type === 'start') {
        setStartTimes(prev => ({
          ...prev,
          [`${rowIndex}-${period}`]: formattedTime
        }))
      } else if (type === 'end') {
        setEndTimes(prev => ({
          ...prev,
          [`${rowIndex}-${period}`]: formattedTime
        }))
      }
    } else {
      if (type === 'start') {
        setStartTimes(prev => ({
          ...prev,
          [`${rowIndex}-${period}`]: ''
        }))
      } else if (type === 'end') {
        setEndTimes(prev => ({
          ...prev,
          [`${rowIndex}-${period}`]: ''
        }))
      }
    }
  }

  const handleOpenDrawer = (rowIndex, day, period) => {
    setCurrentSelection({ rowIndex, day, period })
    setDrawerOpen(true)
  }

  const handleOpenDrawerTeacher = (rowIndex, day, period) => {
    setCurrentSelectionTeacher({ rowIndex, day, period })
    setDrawerOpenTeacher(true)
  }

  const [submittedPeriods, setSubmittedPeriods] = React.useState([])

  const handleSubmit = () => {
    let isValid = true
    const missingDataPeriods = []
    const submittedPeriods = []

    // Loop through the selected classes and teachers
    Object.keys(selectedClasses).forEach(rowIndex => {
      Object.keys(selectedClasses[rowIndex] || {}).forEach(day => {
        Object.keys(selectedClasses[rowIndex][day] || {}).forEach(period => {
          const subject = selectedClasses[rowIndex][day][period] // Get the selected subject
          const teacher = selectedTeachers[rowIndex]?.[day]?.[period] // Get the selected teacher

          // Only check periods where either subject or teacher is selected
          if ((subject && !teacher) || (!subject && teacher)) {
            isValid = false
            missingDataPeriods.push({ rowIndex, day, period, subject, teacher })
          } else {
            // If the period is valid, push it to the submitted periods
            submittedPeriods.push({ rowIndex, day, period, subject, teacher })
          }
        })
      })
    })

    // If validation fails, show an error and stop submission
    if (!isValid) {
      alert(
        'Error: For each selected subject, both subject and teacher must be selected, or neither should be selected.'
      )
      console.error(
        'Validation failed for the following periods (subject/teacher mismatch):',
        missingDataPeriods
      )
      return // Stop the submission process
    }

    // Update the state to store the successfully submitted periods
    setSubmittedPeriods(submittedPeriods)

    console.log('Submission successful', submittedPeriods)

    // If validation passes, proceed with submission
    const formattedData = formatTimetableData()
    const updateTimeTableApi = async () => {
      try {
        const response = await UpdateTimeTableApi(
          JSON.stringify(formattedData, null, 2)
        )
        if (response) {
          window.location.reload()
        }
      } catch (error) {
        console.error('Error updating timetable:', error)
      }
    }
    updateTimeTableApi()
  }

  const handleSubjectSelect = subject => {
    const { rowIndex, day, period } = currentSelection
    if (rowIndex !== null && day && period) {
      handleClassChange(rowIndex, day, period, subject) // Use your existing handler
    }
    setDrawerOpen(false)
  }

  const handleTeacherSelect = subject => {
    const { rowIndex, day, period } = currentSelectionTeacher
    if (rowIndex !== null && day && period) {
      handleTeacherChange(rowIndex, day, period, subject) // Use your existing handler
    }
    setDrawerOpenTeacher(false)
  }

  const handleRemovePeriod = period => {
    setPeriods(prev => prev.filter(p => p !== period))
    // Optionally clear the data related to the deleted period
    const updatedSelectedClasses = { ...selectedClasses }
    const updatedSelectedTeachers = { ...selectedTeachers }
    Object.keys(updatedSelectedClasses).forEach(rowIndex => {
      Object.keys(updatedSelectedClasses[rowIndex] || {}).forEach(day => {
        delete updatedSelectedClasses[rowIndex][day][period]
        delete updatedSelectedTeachers[rowIndex][day][period]
      })
    })
    setSelectedClasses(updatedSelectedClasses)
    setSelectedTeachers(updatedSelectedTeachers)
    setStartTimes(prev => {
      const updatedTimes = { ...prev }
      Object.keys(prev).forEach(key => {
        if (key.endsWith(`-${period}`)) delete updatedTimes[key]
      })
      return updatedTimes
    })
    setEndTimes(prev => {
      const updatedTimes = { ...prev }
      Object.keys(prev).forEach(key => {
        if (key.endsWith(`-${period}`)) delete updatedTimes[key]
      })
      return updatedTimes
    })
  }

  const groupedPeriods = submittedPeriods.reduce(
    (acc, { rowIndex, day, period, subject, teacher }) => {
      const className = `Class ${rowIndex}`
      if (!acc[className]) acc[className] = []
      acc[className].push({ day, period, subject, teacher })
      return acc
    },
    {}
  )
  const handleOpenDrawerTime = (rowIndex, period, type) => {
    setDrawerOpenTime(`${rowIndex}-${period}-${type}`)
  }

  const [drawerOpenTime, setDrawerOpenTime] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  return (
    <div className='TimeTableDetails' data-testid='TimeTableDetails'>
      <TableContainer
        style={{ maxHeight: "500px", overflowY: "auto" }}
        ref={tableContainerRef}
        component={Paper}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Start Time
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                End Time
              </TableCell>
              {[
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday'
              ].map(day => (
                <TableCell
                  key={day}
                  sx={{ color: theme.palette.primary.main }}
                  align='center'
                >
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </TableCell>
              ))}
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timetableData.map((row, rowIndex) =>
              periods.map(period => (
                <TableRow key={rowIndex + period}>
                  {/* Time Picker for Start Time */}
                  <TableCell align='center'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        sx={{
                          cursor: 'pointer',
                          color: '#535353',
                          backgroundColor: '#f9f9f9',
                          padding: '11px',
                          // borderRadius: "8px",
                          minHeight: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minWidth: '140px',
                          margin: '5px',
                          border: '1px solid #ccc',
                          boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
                          // "&:hover": {
                          //   borderColor: "#1976d2",
                          //   backgroundColor: "#f1f8fe",
                          // },
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                          },
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                            fontSize: '16px',
                            // fontWeight: "bold",
                            color: '#333'
                          }
                        }}
                        value={
                          startTimes[`${rowIndex}-${period}`]
                            ? dayjs(
                                `1970-01-01T${
                                  startTimes[`${rowIndex}-${period}`]
                                }:00`
                              )
                            : null
                        }
                        onChange={newValue =>
                          handleTimeChange(rowIndex, period, 'start', newValue)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            placeholder='Set Start Time'
                            InputProps={{
                              ...params.InputProps,
                              className: 'custom-time-picker' // Apply custom class
                            }}
                            sx={{
                              cursor: 'pointer',
                              color: '#535353',
                              background: '#ffffff',
                              padding: '3px',
                              minHeight: '40px',
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minWidth: '140px',
                              marginBottom: '5px',

                              '& .MuiInputBase-root': {
                                display: 'none' // Hide the default input appearance
                              }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </TableCell>

                  {/* Time Picker for End Time */}
                  <TableCell align='center'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        placeholder='Set End Time'
                        sx={{
                          cursor: 'pointer',
                          color: '#535353',
                          backgroundColor: '#f9f9f9',
                          padding: '11px',
                          // borderRadius: "8px",
                          minHeight: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minWidth: '140px',
                          margin: '5px',
                          border: '1px solid #ccc',
                          boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
                          // "&:hover": {
                          //   borderColor: "#1976d2",
                          //   backgroundColor: "#f1f8fe",
                          // },
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                          },
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                            fontSize: '16px',
                            // fontWeight: "bold",
                            color: '#333'
                          }
                        }}
                        value={
                          endTimes[`${rowIndex}-${period}`]
                            ? dayjs(
                                `1970-01-01T${
                                  endTimes[`${rowIndex}-${period}`]
                                }:00`
                              )
                            : null
                        }
                        minTime={
                          startTimes[`${rowIndex}-${period}`]
                            ? dayjs(
                                `1970-01-01T${
                                  startTimes[`${rowIndex}-${period}`]
                                }:00`
                              )
                            : null
                        }
                        onChange={newValue =>
                          handleTimeChange(rowIndex, period, 'end', newValue)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            placeholder='Set End Time'
                            InputProps={{
                              ...params.InputProps,
                              className: 'custom-time-picker' // Apply custom class
                            }}
                            sx={{
                              cursor: 'pointer',
                              color: '#535353',
                              background: '#ffffff',
                              padding: '3px',
                              minHeight: '40px',
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minWidth: '140px',
                              marginBottom: '5px'
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </TableCell>

                  {[
                    'monday',
                    'tuesday',
                    'wednesday',
                    'thursday',
                    'friday',
                    'saturday',
                    'sunday'
                  ].map(day => (
                    <TableCell key={day} align='center'>
                      <div
                        style={{
                          cursor: 'pointer',
                          color: '#535353',
                          background: '#ffffff',
                          padding: '3px',
                          minHeight: '40px',
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minWidth: '140px',
                          marginBottom: '5px'
                        }}
                        onClick={() => handleOpenDrawer(rowIndex, day, period)}
                      >
                        {selectedClasses[rowIndex]?.[day]?.[period]
                          ?.subjectName || 'Select Subject'}
                      </div>
                      <SwipeableDrawer
                        anchor='bottom'
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        BackdropProps={{ invisible: true }}
                      >
                        <AppBar position='sticky' color='primary'>
                          <Toolbar>
                            <Typography
                              variant='h6'
                              style={{ flexGrow: 1, textAlign: 'center' }}
                            >
                              Select Subject
                            </Typography>
                            <IconButton
                              edge='end'
                              color='inherit'
                              onClick={() => setDrawerOpen(false)}
                              aria-label='close'
                            >
                              <CloseIcon />
                            </IconButton>
                          </Toolbar>
                        </AppBar>
                        <List sx={{ padding: '15px' }}>
                          {subjects.map(subject => (
                            <ListItem
                              button
                              key={subject.id}
                              onClick={() => handleSubjectSelect(subject)}
                              sx={{
                                border: '1px solid #aaaaaa',
                                borderRadius: '15px',
                                marginBottom: '15px'
                              }}
                            >
                              <ListItemText primary={subject.subjectName} />
                            </ListItem>
                          ))}
                        </List>
                      </SwipeableDrawer>

                      <div
                        style={{
                          cursor: 'pointer',
                          color: '#535353',
                          background: '#ffffff',
                          padding: '3px',
                          minHeight: '40px',
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minWidth: '140px',
                          marginBottom: '5px'
                        }}
                        onClick={() =>
                          handleOpenDrawerTeacher(rowIndex, day, period)
                        }
                      >
                        {selectedTeachers[rowIndex]?.[day]?.[period]?.teacher ||
                          'Select Teacher'}
                      </div>

                      <SwipeableDrawer
                        anchor='bottom'
                        open={drawerOpenTeacher}
                        onClose={() => setDrawerOpenTeacher(false)}
                        BackdropProps={{ invisible: true }}
                      >
                        <AppBar position='sticky' color='primary'>
                          <Toolbar>
                            <Typography
                              variant='h6'
                              style={{ flexGrow: 1, textAlign: 'center' }}
                            >
                              Select Teacher
                            </Typography>
                            <IconButton
                              edge='end'
                              color='inherit'
                              onClick={() => setDrawerOpenTeacher(false)}
                              aria-label='close'
                            >
                              <CloseIcon />
                            </IconButton>
                          </Toolbar>
                        </AppBar>
                        <List sx={{ padding: '15px' }}>
                          {teacher?.map(subject => (
                            <ListItem
                              button
                              key={subject.id}
                              onClick={() => handleTeacherSelect(subject)}
                              sx={{
                                border: '1px solid #aaaaaa',
                                borderRadius: '15px',
                                marginBottom: '15px'
                              }}
                            >
                              <ListItemText
                                primary={`${subject.first_name} ${subject.last_name}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </SwipeableDrawer>
                    </TableCell>
                  ))}

                  {/* Delete BUtton */}
                  <TableCell align='center'>
                    <IconButton
                      color='error'
                      onClick={() => handleRemovePeriod(period)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        onClick={handleAddMorePeriods}
        sx={{
          backgroundColor: '#123148',
          padding: '10px',
          color: '#fff',
          position: 'sticky',
          bottom: 50,
          zIndex: 1000,
          width: '100%'
        }}
      >
        <Typography variant='h6' sx={{ textAlign: 'center' }}>
          Add More Periods
        </Typography>
      </Box>
      <Box
        onClick={handleSubmit}
        sx={{
          backgroundColor: theme.palette.primary.main,
          padding: '10px',
          color: '#fff',
          position: 'sticky',
          bottom: 0,
          zIndex: 1000,
          width: '100%'
        }}
      >
        <Typography variant='h6' sx={{ textAlign: 'center' }}>
          Submit
        </Typography>
      </Box>

      {/* {submittedPeriods.length > 0 && (
        <Box sx={{ padding: '20px' }}>
          <Typography variant='h6'>Submitted Periods</Typography>
          {Object.entries(groupedPeriods).map(([className, periods]) => (
            <Box key={className} sx={{ marginBottom: '20px' }}>
              <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                {className}
              </Typography>
              <ul>
                {periods.map((period, index) => (
                  <li key={index}>
                    {period.day} - Period {period.period} (
                    {period.subject
                      ? `Subject: ${period.subject}`
                      : 'No Subject'}{' '}
                    /
                    {period.teacher
                      ? ` Teacher: ${period.teacher}`
                      : 'No Teacher'}
                    )
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </Box>
      )} */}
    </div>
  )
}

TimeTableDetails.propTypes = {
  timetableData: PropTypes.array.isRequired
}

export default TimeTableDetails
