import React from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Typography,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { useAuth } from "../../services/authContext";
import { ListStudentAttendanceApi, StudentAttendanceApi } from "../../services/apis";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: { main: "#e4b648" },
  },
});

const UserAttendance = (props) => {
  const { loginStatus } = useAuth();
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [attendanceData, setAttendanceData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchAttendance = async () => {
      setLoading(true);
      try {
        const formattedDate = selectedDate.format("YYYY-MM-DD");
        const response = await ListStudentAttendanceApi({
          date: formattedDate,
          student_id: loginStatus.user.id,
          id: loginStatus.user.id,
        });
        setAttendanceData(response?.[0] || null); // Assuming only one record is returned for the student
      } catch (error) {
        console.error("Error fetching attendance:", error);
        setAttendanceData(null);
      } finally {
        setLoading(false);
      }
    };

    if (loginStatus.user.id && selectedDate) {
      fetchAttendance();
    }
  }, [loginStatus.user.id, selectedDate]);

  return (
    <div className="UserAttendance" data-testid="UserAttendance">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              padding: "90px 0px",
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}>
            <Container maxWidth="sm" sx={{ flexGrow: 1 }}>
              {loginStatus.token && (
                <Box>
                  <DatePicker
                    label="Select Date"
                    fullWidth
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(dayjs(newValue))}
                    renderInput={(params) => <TextField {...params} sx={{ marginBottom: 3, width: "100%" }} />}
                  />

                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "200px",
                      }}>
                      <CircularProgress />
                    </Box>
                  ) : attendanceData ? (
                    <Accordion defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <strong>Attendance Status:</strong> {attendanceData.attendance}
                          <br />
                          <small style={{ color: "#666" }}>Date: {selectedDate.format("YYYY-MM-DD")}</small>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100px",
                            height: "40px",
                            backgroundColor:
                              attendanceData.attendance === "Present"
                                ? "green"
                                : attendanceData.attendance === "Absent"
                                ? "red"
                                : attendanceData.attendance === "Leave"
                                ? "warning"
                                : "gray",
                          }}>
                          {attendanceData.attendance ? attendanceData.attendance : "NotMarked"}
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Box sx={{ textAlign: "center", marginTop: 3 }}>
                      <Typography>No attendance data found for the selected date.</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Container>
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
};

export default UserAttendance;
