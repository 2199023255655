import React, { useState, useEffect } from "react";
import { useAuth } from "../../services/authContext";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Container,
  createTheme,
  ThemeProvider,
  Button,
  useMediaQuery,
} from "@mui/material";
import { ListStudentHomeworkApi } from "../../services/apis";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { BEBase } from "../../constants/app.constant";

const StudentHomeworkView = () => {
  const [homeworkList, setHomeworkList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { loginStatus } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Fetch homework data for the student
  const fetchStudentHomework = async () => {
    setLoading(true);
    try {
      const response = await ListStudentHomeworkApi({
        homeworkDate: selectedDate,
        id: loginStatus.user.id,
      });
      setHomeworkList(response);
    } catch (error) {
      console.error("Error fetching homework:", error);
    } finally {
      setLoading(false);
    }
  };

  // Call API whenever `selectedDate` changes
  useEffect(() => {
    if (loginStatus.user.id && selectedDate) {
      fetchStudentHomework();
    }
  }, [loginStatus, selectedDate]);

  const theme = createTheme({
    palette: {
      mode: "light",
      primary: { main: "#e4b648" },
      // Add more theme customizations as needed
    },
  });

  const renderMobileCard = (homework) => (
    <Box
      key={homework.id}
      sx={{
        p: 2,
        mb: 2,
        border: "1px solid #e0e0e0",
        borderRadius: 1,
        backgroundColor: "#fff",
        boxShadow: 1,
      }}>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
        Subject: {homework.subject_id || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Heading: {homework.heading || "N/A"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Content: {homework.content || "N/A"}
      </Typography>
      {homework.homeworkSheet && (
        <Button
          variant="contained"
          color="success"
          size="small"
          href={`${BEBase}media/${homework.homeworkSheet}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ mt: 1 }}>
          View
        </Button>
      )}
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container>
          <Box sx={{ mt: 3,py:10 }}>
            {/* Date Picker */}
            <DatePicker
              label="Select Date"
              value={dayjs(selectedDate)}
              onChange={(newValue) => {
                const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                setSelectedDate(formattedDate);
              }}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 3 }} />}
            />

            {/* Homework List */}
            {loading ? (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Loading homework...
              </Typography>
            ) : homeworkList.length > 0 ? (
              isMobile ? (
                // Mobile Card View
                <Box sx={{ mt: 2 }}>{homeworkList.map(renderMobileCard)}</Box>
              ) : (
                // Desktop Table View
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Subject</TableCell>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Heading</TableCell>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Content</TableCell>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Homework Sheet</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {homeworkList.map((homework) => (
                      <TableRow key={homework.id} hover>
                        <TableCell>{homework.subject_id || "N/A"}</TableCell>
                        <TableCell>{homework.heading || "N/A"}</TableCell>
                        <TableCell>{homework.content || "N/A"}</TableCell>
                        <TableCell>
                          {homework.homeworkSheet && (
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              href={`${BEBase}media/${homework.homeworkSheet}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{ mt: 1 }}>
                              View
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )
            ) : (
              <Typography variant="body1" sx={{ mt: 2 }}>
                No homework found for the selected date.
              </Typography>
            )}
          </Box>
        </Container>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default StudentHomeworkView;
