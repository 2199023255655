import React from 'react'
import PropTypes from 'prop-types'
import './StudentAttendanceDetails.scss'
import {
  Container,
  Box,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAuth } from '../../services/authContext'
import {
  ListStudentsApi,
  StudentAttendanceApi,
  ListStudentAttendanceApi
} from '../../services/apis'
import dayjs from 'dayjs'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
  }
})

const StudentAttendanceDetails = props => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [selectedDate, setSelectedDate] = React.useState(dayjs())
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [listStudentAttendance, setListStudentAttendance] = React.useState([])
  const [loading, setLoading] = React.useState(false) // Loading state

  React.useEffect(() => {

    if (
      listApiFlag &&
      loginStatus.user &&
      (loginStatus.user.staff_type.toLowerCase() === 'admin' ||
        loginStatus.user.staff_type.toLowerCase() === 'teacher')
    ) {
      const fetchListStudents = async () => {
        try {
          const response = await ListStudentsApi({
            id: JSON.parse(loginStatus.user.id)
          })
          
          setListOfUsers(response) // This will only work if the response format is correct
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListStudents()
    }
  }, [loginStatus, listApiFlag])

  React.useEffect(() => {
    const fetchListStudentsAttendance = async () => {
      setLoading(true) // Start loading
      try {
        setListStudentAttendance([]);
        const formattedDate = { date: selectedDate.format('YYYY-MM-DD') , id: JSON.parse(loginStatus.user.id)}
        const response = await ListStudentAttendanceApi(formattedDate)
        setListStudentAttendance(response)
      } catch (error) {
        console.error('Error fetching attendance:', error)
      } finally {
        setLoading(false) // Stop loading
      }
    }

    if (selectedDate) {
      fetchListStudentsAttendance()
    }
  }, [selectedDate])

  const handleAttendanceClick = async (attendance) => {
    console.log('Handle Click - Attendance:', attendance.attendance, attendance)
    console.log('Handle Click - Student ID:', attendance.studentId)

    const formattedDate = selectedDate.format('YYYY-MM-DD')
    const attendanceData = JSON.stringify({
      attendance : attendance.attendance,
      student: attendance.studentId,
      date: formattedDate
    })

    try {
      const response = await StudentAttendanceApi(attendanceData)
      console.log('API Response:', response)

      if (response && response.task && response.task.attendance) {
        setListStudentAttendance(prevState => {
          const updatedList = prevState.map(item =>
            item.student === attendance.studentId
              ? { ...item, attendance: response.task.attendance }
              : item
          )

          if (!prevState.some(item => item.student === attendance.studentId)) {
            updatedList.push({
              student: attendance.studentId,
              attendance: response.task.attendance
            })
          }

          console.log('Updated Attendance List:', updatedList)
          return updatedList
        })
      } else {
        console.error('No attendance data found in API response:', response)
      }
    } catch (error) {
      console.error('Error updating attendance:', error)
    }
  }

  const getAttendanceStatus = studentId => {
    const attendance = listStudentAttendance.find(
      attendance => attendance.student === studentId
    )
    return attendance ? attendance.attendance : null
  }

  const filteredUsers = listOfUsers.filter(user => {
    const firstNameMatches = user.first_name
      ? user.first_name.toLowerCase().includes(filter.toLowerCase())
      : false
    const lastNameMatches = user.last_name
      ? user.last_name.toLowerCase().includes(filter.toLowerCase())
      : false
    const classMatches =
      user.student_class == props.studentAttendanceData[0]?.section_id
    return (firstNameMatches || lastNameMatches) && classMatches
  })

  return (
    <div
      className='StudentAttendanceDetails'
      data-testid='StudentAttendanceDetails'
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            className='CourseManagement'
            sx={{
              padding: '90px 0px',
              display: 'flex',
              flexDirection: 'column',
              height: '100vh'
            }}
          >
            <Container maxWidth='lg' sx={{ flexGrow: 1 }}>
              {loginStatus.token && (
                <Box>
                  {(loginStatus.user.staff_type.toLowerCase() === 'admin' ||
                    loginStatus.user.staff_type.toLowerCase() ===
                      'teacher') && (
                    <div className='table-cover'>
                      <DatePicker
                        label='Select Date'
                        fullWidth
                        value={selectedDate}
                        onChange={newValue => setSelectedDate(dayjs(newValue))}
                        renderInput={params => (
                          <TextField
                            {...params}
                            sx={{ marginBottom: 3, width: '100%' }}
                          />
                        )}
                      />

                      {loading ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '243px'
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          <TextField
                            fullWidth
                            label='Filter Students'
                            id='filter-students'
                            value={filter}
                            sx={{ marginBottom: 3 }}
                            onChange={e => setFilter(e.target.value)}
                          />

                          {filteredUsers.map(item => {
                            const attendanceStatus = getAttendanceStatus(
                              item.id
                            )
                            return (
                              <Accordion defaultExpanded key={item.id}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <div>
                                    {item.first_name} {item.last_name}
                                    <br />
                                    <small style={{ color: '#666' }}>
                                      {item.email} ({item.phone})
                                    </small>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                  }}
                                >
                                  <Button
                                    variant={
                                      attendanceStatus === 'Present'
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    disabled={attendanceStatus === 'Present'}
                                    onClick={() =>
                                      handleAttendanceClick({attendance : 'Present', studentId : item.id})
                                    }
                                    sx={{ width: '100px', height: '40px' }}
                                  >
                                    Present
                                  </Button>
                                  <Button
                                    variant={
                                      attendanceStatus === 'Absent'
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    disabled={attendanceStatus === 'Absent'}
                                    onClick={() =>
                                      handleAttendanceClick({attendance : 'Absent', studentId : item.id})
                                    }
                                    sx={{ width: '100px', height: '40px' }}
                                  >
                                    Absent
                                  </Button>
                                  <Button
                                    variant={
                                      attendanceStatus === 'Leave'
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    disabled={attendanceStatus === 'Leave'}
                                    onClick={() =>
                                      handleAttendanceClick({attendance : 'Leave', studentId : item.id})
                                    }
                                    sx={{ width: '100px', height: '40px' }}
                                  >
                                    Leave
                                  </Button>
                                </AccordionDetails>
                              </Accordion>
                            )
                          })}
                        </>
                      )}
                    </div>
                  )}
                </Box>
              )}
            </Container>
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  )
}

StudentAttendanceDetails.propTypes = {}

export default StudentAttendanceDetails
