import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Container } from "@mui/material";
import { ListTimeTableApi } from "../../services/apis"; // Replace with your actual API call
import { useAuth } from "../../services/authContext";

const ViewTimeTable = () => {
  const [timetableData, setTimetableData] = useState([]);
  const [periods, setPeriods] = useState([]);
  const { loginStatus } = useAuth();

  useEffect(() => {
    const fetchTimetable = async () => {
      try {
        const response = await ListTimeTableApi({ student_id: loginStatus.user.id });
        if (response) {
          // Update parsing to handle the new response format
          const parsedTimetable = response.map((entry) => ({
            ...entry,
            monday: JSON.parse(entry.monday),
            tuesday: JSON.parse(entry.tuesday),
            wednesday: JSON.parse(entry.wednesday),
            thursday: JSON.parse(entry.thursday),
            friday: JSON.parse(entry.friday),
            saturday: JSON.parse(entry.saturday),
            sunday: JSON.parse(entry.sunday),
          }));

          setTimetableData(parsedTimetable);

          // Extract periods from first day's data
          if (parsedTimetable.length > 0) {
            const firstDayPeriods = Object.keys(parsedTimetable[0].monday || {});
            setPeriods(firstDayPeriods);
          }
        }
      } catch (error) {
        console.error("Error fetching timetable:", error);
      }
    };

    fetchTimetable();
  }, [loginStatus.user.id]);

  return (
    <Box sx={{ padding: "20px", py: 10 }}>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Weekly Timetable">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", color: "#e4b648" }}>Start Time</TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#e4b648" }}>End Time</TableCell>
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                  <TableCell key={day} align="center" sx={{ fontWeight: "bold", color: "#e4b648" }}>
                    {day}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {periods.map((period) => (
                <TableRow key={period}>
                  {/* Start Time Cell */}
                  <TableCell align="center">{timetableData[0]?.monday[period]?.startTime || "No Time"}</TableCell>

                  {/* End Time Cell */}
                  <TableCell align="center">{timetableData[0]?.monday[period]?.endTime || "No Time"}</TableCell>

                  {/* Days Cells */}
                  {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day) => (
                    <TableCell key={day} align="center">
                      {timetableData.map((row, idx) => {
                        const periodData = row[day][period];
                        return periodData?.subject?.subjectName ? (
                          <div
                            key={idx}
                           >
                                 <div
                              style={{
                                cursor: "pointer",
                                color: "#535353",
                                background: "#ffffff",
                                padding: "3px",
                                minHeight: "40px",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "center",
                                minWidth: "140px",
                                marginBottom: "5px",
                              }}
                            >
                                 {periodData.subject.subjectName}
                            </div>
                            <div
                              style={{
                                cursor: "pointer",
                                color: "#535353",
                                background: "#ffffff",
                                padding: "3px",
                                minHeight: "40px",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "center",
                                minWidth: "140px",
                                marginBottom: "5px",
                              }}
                            >
                                 {periodData.teacher.teacher}
                            </div>
                            {/* <Typography sx={{ fontWeight: "light", color: "#535353" }}>
                              {periodData.teacher.teacher}
                            </Typography> */}
                          </div>
                        ) : (
                          <Typography key={idx} variant="body2" color="text.secondary">
                            No Class
                          </Typography>
                        );
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default ViewTimeTable;
