import React from 'react';
import PropTypes from 'prop-types';
import './FeesManagement.scss';
import { useAuth } from '../../services/authContext';
import { Container, Box, Fab, TextField, Button, Modal, Typography, Divider, List, ListItem, IconButton, Accordion, AccordionActions, AccordionSummary, AccordionDetails, Menu, MenuItem, Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ListProductApi, CreateTaskApi, ListFeesApi, CreateFeesApi, UpdateFeesApi, DeleteFeesApi, UpdateFeesImageApi, DeleteSectionApi } from '../../services/apis';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import { BEBase } from '../../constants/app.constant';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { jsPDF } from 'jspdf';




const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' },
    // Add more theme customizations as needed
  },
});

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vW",
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH'
};

const UpdateStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vW",
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH'
};

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%", // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH',
  '@media (max-width: 600px)': { // Adjust the breakpoint as needed
    width: '100%', // Width for mobile devices
  }
};

const options = [
  "Update",
  "Delete"
];

const ITEM_HEIGHT = 48;


const FeesManagement = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [heading, setHeading] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [paymentLink, setPaymentLink] = React.useState("");
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [imgOpen, setImgOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState("")
  const [classList, setCLassList] = React.useState([]);
  const [openedImage, setOpenedImage] = React.useState("");
  const [syllabus, setSyllabus] = React.useState();
  const [changeImgFlag, setChangeImgFlag] = React.useState("");
  const [imageCacheKey, setImageCacheKey] = React.useState(Date.now());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openme = Boolean(anchorEl);
  const [anchorElAccordion, setAnchorElAccordion] = React.useState(null);
  const openmeAccordion = Boolean(anchorElAccordion);
  const [selectedDropDown, setSelectedDropDown] = React.useState("");
  const [deleteSectionOpen, setDeleteSectionOpen] = React.useState(false);
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false);
  const [openMenuId, setOpenMenuId] = React.useState(null);
  const [className, setClassName] = React.useState("");
  const [feesName, setFeesName] = React.useState("");
  const [feesAmount, setFeesAmount] = React.useState("");
  const [feesDuration, setFeesDuration] = React.useState("");
  const [feesList, setFeesList] = React.useState([]);


  const handleClick = async (event, selectID) => {
    console.log("ancholEl", event, event.currentTarget, selectID);
    setAnchorEl(event.currentTarget);
    setSelectedDropDown(selectID);
  };

  const handleAccordianClick = (event, selectID) => {
    event.stopPropagation();
    console.log("ancholElAccordian", event, event.currentTarget, selectID);
    setAnchorElAccordion(event.currentTarget); // Set the anchor for the accordion menu
    setOpenMenuId(selectID); // Set the id for the menu being opened
  };

  const handleCloseMe = () => {
    setAnchorEl(null);
  };

  const handleCloseMeAccordion = () => {
    setAnchorElAccordion(null);
    setOpenMenuId(null);
  };

  const handleAction = async (action) => {
    if ((action.toLowerCase() === "delete")) {
      setDeleteSectionOpen(true);
    }
  };

  const handleAccordianAction = async (action, item) => {
    console.log("getitemmm", item)
    if ((action.toLowerCase() === "delete")) {
      setDeleteProductOpen(true);
      setSelectedID(item.id);
    } else if ((action.toLowerCase() === "update")) {
      const textBoxArr = [];
      setSelectedID(item.id);
      setClassName(item.className);
      setFeesName(item.feesName);
      setFeesAmount(item.feesAmount);
      setFeesDuration(item.feesDuration);
      handleOpen()
      //setDeleteProductOpen(true);
    }
  }

  const handleSectionDelete = async (action) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedDropDown.id);

      // Assuming DeleteFeesApi expects formData
      const response = await DeleteSectionApi(formData);

      console.log("Product deleted successfully:", response);
      handleClose();
    } catch (error) {
      console.error("Error deleting Product:", error);
    }
  }

  const makePdfAndDownload = async (invoiceData) => {

    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Title of the invoice
    doc.setFontSize(25);
    doc.text('Invoice', 105, 20, { align: 'center' });

    // Add some space before starting the details
    doc.setFontSize(12);
    doc.text('Invoice ID: ' + invoiceData.id, 20, 40);
    doc.text('Class: ' + invoiceData.className, 20, 50);
    doc.text('Fees: ' + invoiceData.feesName, 20, 60);
    doc.text('Amount: INR ' + invoiceData.feesAmount, 20, 70);
    doc.text('Duration: ' + invoiceData.feesDuration, 20, 80);

    // Optional: Add a line under the details for visual separation
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.line(20, 90, 190, 90);  // Horizontal line

    // Add some more details or notes if needed
    doc.setFontSize(10);
    doc.text('Thank you for choosing our services!', 20, 100);
    let loginCred = JSON.parse(JSON.stringify(loginStatus));
    // Add a footer or company info (optional)
    console.log('getlogcred', loginCred, loginCred.user);
    doc.text(loginCred.user.firstName + " " + loginCred.user.lastName, 20, 280);
    doc.text(loginCred.user.address + " " + loginCred.user.phone, 20, 290);

    // Save the PDF or trigger a download
    doc.save('invoice_' + invoiceData.id + '.pdf');
  };


  React.useEffect(() => {
    if (listApiFlag && loginStatus.user && loginStatus.user.staff_type === "admin" || loginStatus.user.staff_type === "student") {
      const fetchListProducts = async () => {
        try {
          if (loginStatus.user.staff_type === "student") {
            const response = await ListFeesApi(loginStatus.user.id,true);
            setListOfUsers(response);
            setListApiFlag(false);
          }
          else {
            const response = await ListFeesApi(loginStatus.user.id);
            setListOfUsers(response);
            setListApiFlag(false);
          }
        } catch (error) {
          console.error("Error fetching users:", error);
          setListApiFlag(false);
        }
        console.log(loginStatus)
      };

      const fetchListClasses = async () => {
        try {
          const response = await ListProductApi();
          response.forEach(element => {
            element.title = element.productName
          });

          setCLassList(response);
          setListApiFlag(false);
        } catch (error) {
          console.error("Error fetching users:", error);
          setListApiFlag(false);
        }
      };

      fetchListProducts();
      fetchListClasses();
    }

  }, [loginStatus]);

  const handleAddFee = () => {
    setFeesList([...feesList, { feesName, feesAmount, feesDuration }]);
    setFeesName('');
    setFeesAmount('');
    setFeesDuration('');
  };

  const handleUpdateTask = async () => {
    try {

      const formData = JSON.stringify({
        'id': selectedID,
        'className': className,
        'feesName': feesName,
        'feesAmount': feesAmount,
        'feesDuration': feesDuration,
      });

      const response = await UpdateFeesApi(formData);
      console.log("Product created successfully:", response);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Error creating Product:", error);
    }
  }

  const filteredUsers = listOfUsers.filter(user => {
    return user.className.toLowerCase().includes(filter.toLowerCase());
  });



  const [open, setOpen] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpen = (item) => {
    const textBoxArr = [];
    setSelectedID(item.id);
    setClassName(item.className);
    setFeesName(item.feesName);
    setFeesAmount(item.feesAmount);
    setFeesDuration(item.feesDuration);
    setOpen(true);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleImageOpen = (path) => {
    setOpenedImage(path);
    setImgOpen(true);
    setImageCacheKey(Date.now());
  };
  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
  }

  const handleCreateProduct = async () => {
    try {
      for (const fee of feesList) {
        const authToken = JSON.stringify(loginStatus);
        const formData = JSON.stringify({
          'className': className,
          'feesName': fee.feesName,
          'feesAmount': fee.feesAmount,
          'feesDuration': fee.feesDuration,
          'adminToken': JSON.parse(authToken).token
        });

        const response = await CreateFeesApi(formData); // Assuming CreateFeesApi is your API call function
        console.log("Product created successfully:", response);
      }

      window.location.reload();
      handleClose();
    } catch (error) {
      console.error("Error creating Product:", error);
    }
  };

  const handleDeleteProduct = async (deleteId) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedID);

      // Assuming DeleteFeesApi expects formData
      const response = await DeleteFeesApi(formData);

      console.log("Product deleted successfully:", response);
      handleClose();
    } catch (error) {
      console.error("Error deleting Product:", error);
    }
  };



  return (
    <ThemeProvider theme={theme}>
      <Box className="FeesManagement" data-testid="FeesManagement" sx={{ padding: '90px 0px', display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
          {loginStatus.token && (
            <Box>
              {loginStatus.user.staff_type === "admin" || loginStatus.user.staff_type === "student" ? (
                <div className='table-cover'>
                  <Box sx={{ position: 'absolute', bottom: 76, right: 16 }}>
                    {loginStatus.user.staff_type != "student" &&
                      <Fab color="primary" aria-label="add" onClick={handleOpenCreate} sx={{ position: "fixed", right: "30px", bottom: "70px" }}>
                        <AddIcon />
                      </Fab>
                    }
                  </Box>
                  <TextField
                    fullWidth
                    label="Filter Class"
                    id="fullWidth"
                    value={filter}
                    sx={{ marginBottom: 3 }}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  {filteredUsers.filter((item, index, self) =>
                    index === self.findIndex((t) => t.className === item.className)
                  )
                    .map((item) => (
                      <Accordion defaultExpanded key={item.id}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <div>
                            {item.className}
                            <br />
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            <Thead>
                              <Tr>
                                <Th>Fees Name</Th>
                                <Th>Fees Amount</Th>
                                <Th>Fees Duration</Th>
                                {/*<Th>Class Pics</Th>*/}
                                {loginStatus.user.staff_type != "student" &&
                                  <Th>Actions</Th>
                                }
                                <Th>Quote Sheet</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {filteredUsers.map((users) => (
                                <>
                                  {users.className === item.className ? (
                                    <Tr key={users.id}>
                                      <Td>{users.feesName}</Td>
                                      <Td>{users.feesAmount}</Td>
                                      <Td>{users.feesDuration}</Td>
                                      {loginStatus.user.staff_type != "student" &&
                                        <Td><Button variant="contained" className='btn' onClick={() => handleOpen(users)}>Update</Button>
                                          <Button variant="contained" color="error" className='btn' onClick={() => handleAccordianAction('delete', users)}>Delete</Button>
                                        </Td>
                                      }

                                      <Td>
                                        <Button onClick={() => makePdfAndDownload(users)} variant="contained" color="success">
                                          Download
                                        </Button>
                                      </Td>
                                    </Tr>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </Tbody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>

                    ))}
                  {loginStatus.user.staff_type != "student" && <>
                    <Modal
                      fullscreen
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={{ ...UpdateStyle, position: 'relative' }}>
                        <CloseIcon
                          onClick={handleClose}
                          style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                        />
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 3 }}>
                          Update Fees
                        </Typography>
                        <Autocomplete
                          id="free-solo-demo-class"
                          options={classList.map((option) => option.title)}
                          value={className}
                          required
                          onChange={(event, newValue) => setClassName(newValue)}
                          renderInput={(params) => <TextField fullWidth {...params} label="Select Class" required error={!className} />}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          label="Fees Name"
                          className='register-form'
                          value={feesName}
                          onChange={(e) => setFeesName(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />

                        <TextField
                          fullWidth
                          required
                          label="Fees Amount"
                          className='register-form'
                          value={feesAmount}
                          onChange={(e) => setFeesAmount(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />

                        <Autocomplete
                          id="free-solo-demo"
                          value={feesDuration}
                          options={[{ title: "Monthly" }, { title: "Quarterly" }, { title: "Yearly" }, { title: "LumpSum" }].map((option) => option.title)}
                          onChange={(event, newValue) => setFeesDuration(newValue)}
                          renderInput={(params) => <TextField fullWidth {...params} label="Fees Duration" />}
                          sx={{ marginBottom: 3 }}
                        />

                        <Button variant="contained" className='btn' onClick={handleUpdateTask}>Update Fees</Button>
                      </Box>
                    </Modal>
                    <Modal
                      fullscreen
                      open={deleteProductOpen}
                      onClose={() => setDeleteProductOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={{ ...ImgStyle, position: 'relative' }}>
                        <CloseIcon
                          onClick={() => setDeleteProductOpen(false)}
                          style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                        />
                        <h3>Are you Sure You want to delete this Class</h3>
                        <Button variant="contained" className='btn' onClick={() => {
                          handleDeleteProduct()
                        }}>Yes</Button>
                        <Button variant="contained" className='btn' onClick={() => setDeleteProductOpen(false)}>No</Button>
                      </Box>
                    </Modal>
                    <Modal
                      fullscreen
                      open={openCreate}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      sx={{ overflow: 'auto' }}
                    >
                      <Box sx={{ ...style, position: 'relative' }}>
                        <CloseIcon
                          onClick={handleClose}
                          style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                        />
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 3 }}>
                          Create New Fees
                        </Typography>

                        <Autocomplete
                          id="free-solo-demo-class"
                          options={classList.map((option) => option.title)}
                          value={className}
                          required
                          onChange={(event, newValue) => setClassName(newValue)}
                          renderInput={(params) => <TextField fullWidth {...params} label="Select Class" required error={!className} />}
                          sx={{ marginBottom: 3 }}
                        />

                        {/* Render dynamic fee fields for each fee in feesList */}
                        {feesList.map((fee, index) => (
                          <Box key={index} sx={{ marginBottom: 3 }}>
                            <Typography variant="body1" sx={{ marginBottom: 1 }}>
                              Fee #{index + 1}
                            </Typography>
                            <TextField
                              fullWidth
                              required
                              label="Fees Name"
                              className="register-form"
                              value={fee.feesName}
                              onChange={(e) => {
                                const updatedFees = [...feesList];
                                updatedFees[index].feesName = e.target.value;
                                setFeesList(updatedFees);
                              }}
                              sx={{ marginBottom: 1 }}
                            />
                            <TextField
                              fullWidth
                              required
                              label="Fees Amount"
                              className="register-form"
                              value={fee.feesAmount}
                              onChange={(e) => {
                                const updatedFees = [...feesList];
                                updatedFees[index].feesAmount = e.target.value;
                                setFeesList(updatedFees);
                              }}
                              sx={{ marginBottom: 1 }}
                            />
                            <Autocomplete
                              id="fee-duration"
                              options={['Monthly', 'Quarterly', 'Yearly', 'LumpSum']}
                              value={fee.feesDuration}
                              onChange={(event, newValue) => {
                                const updatedFees = [...feesList];
                                updatedFees[index].feesDuration = newValue;
                                setFeesList(updatedFees);
                              }}
                              renderInput={(params) => <TextField fullWidth {...params} label="Fees Duration" />}
                              sx={{ marginBottom: 1 }}
                            />
                          </Box>
                        ))}

                        {/* Add another fee button */}
                        <Button variant="outlined" onClick={handleAddFee} sx={{ marginBottom: 3 }}>
                          Add Fee
                        </Button>

                        {/* Single button to create all fees */}
                        <Button variant="contained" className="btn" onClick={handleCreateProduct}>
                          Create All Fees
                        </Button>
                      </Box>
                    </Modal>
                  </>}

                </div>
              ) : (
                <div className="task-cover">
                  {filteredUsers.map((item) => (
                    <>
                      <Divider variant='inset' sx={{ marginLeft: "0px" }} />
                      <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            primary={item.taskHeading}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{ color: 'text.primary', display: 'inline' }}
                                >
                                  {item.taskDescription}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        {item.amount > 0 ?
                          <Button component="a" href={item.paymentLink}>
                            Pay Rs. {item.amount}
                          </Button>
                          :
                          <></>
                        }
                      </List>
                      <Divider variant='inset' sx={{ marginLeft: "0px" }} />
                    </>
                  )
                  )}
                </div>
              )}
            </Box>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

FeesManagement.propTypes = {};

export default FeesManagement;
