import React from 'react'
import './StudentManagement.scss'
import { useAuth } from '../../services/authContext'
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Divider,
  List,
  ListItem,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  Drawer
} from '@mui/material'
import {
  ListProductApi,
  ListTimeTableApi} from '../../services/apis'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ListItemText from '@mui/material/ListItemText'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import StudentDetails from '../StudentDetails/StudentDetails'
import { ArrowBack } from '@mui/icons-material'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
    // Add more theme customizations as needed
  }
})

const ITEM_HEIGHT = 48

const StudentManagement = () => {
  const { loginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState('')
  const [heading, setHeading] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [amount, setAmount] = React.useState('')
  const [paymentLink, setPaymentLink] = React.useState('')
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [productName, setProductName] = React.useState('')
  const [productDescription, setProductDescriptionn] = React.useState('')
  const [productCategory, setProductCategory] = React.useState('')
  const [productPrice, setProductPrice] = React.useState('')
  const [discountedPrice, setDiscountedPrice] = React.useState('')
  const [productPic1, setProductPic1] = React.useState()
  const [productPic2, setProductPic2] = React.useState()
  const [productPic3, setProductPic3] = React.useState()
  const [productPic4, setProductPic4] = React.useState()
  const [productPic5, setProductPic5] = React.useState()
  const [imgOpen, setImgOpen] = React.useState(false)
  const [selectedID, setSelectedID] = React.useState('')
  const [openedImage, setOpenedImage] = React.useState('')
  const [syllabus, setSyllabus] = React.useState()
  const [changeImgFlag, setChangeImgFlag] = React.useState('')
  const [imageCacheKey, setImageCacheKey] = React.useState(Date.now())
  // State to manage dynamically added textboxes
  const [textboxes, setTextboxes] = React.useState([])
  const [textboxId, setTextBoxId] = React.useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElAccordion, setAnchorElAccordion] = React.useState(null)
  const [selectedDropDown, setSelectedDropDown] = React.useState('')
  const [deleteSectionOpen, setDeleteSectionOpen] = React.useState(false)
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false)
  const [openMenuId, setOpenMenuId] = React.useState(null)
  const [classList, setClassList] = React.useState([])
  const [className, setClassName] = React.useState('')
  const [timetableData, setTimetableData] = React.useState([])
  const [value, setValue] = React.useState('')
  const [addedClassFromSection, setAddedClassFromSection] = React.useState([])

  const handleCloseMeAccordion = () => {
    setAnchorElAccordion(null)
    setOpenMenuId(null)
  }

  // Function to add a new textbox (up to a limit of 10)

  // Function to handle changes in dynamically added textboxes

  React.useEffect(() => {

    if (
      listApiFlag &&
      loginStatus.user &&
      (loginStatus.user.staff_type.toLowerCase() === 'admin' ||
        loginStatus.user.staff_type.toLowerCase() === 'teacher')
    ) {
      const fetchListProducts = async () => {
        try {
          const response = await ListProductApi()
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
    }
  }, [loginStatus])

  const filteredUsers = listOfUsers.filter(user =>
    user.productName.toLowerCase().includes(filter.toLowerCase())
  )

  const [open, setOpen] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }


  const handleClose = () => {
    setOpen(false)
    setOpenCreate(false)
  }

  const showTimeTable = async (e, sectionDetails) => {
    e.stopPropagation()
    try {
      try {
        const payload = {
          className: sectionDetails.section_text,
          id: loginStatus.user.id
        }
        const response = await ListTimeTableApi(payload)

        // Function to remove escape characters and parse the JSON string
        const parseJsonString = jsonString => {
          // Remove the escape characters and parse the JSON string
          return JSON.parse(jsonString.replace(/\\"/g, '"'))
        }

        // Assuming you need to parse the JSON strings for each day of the week
        response.forEach(element => {
          // For each element in the response, parse the timetable data for each day
          element.monday = parseJsonString(element.monday)
          element.tuesday = parseJsonString(element.tuesday)
          element.wednesday = parseJsonString(element.wednesday)
          element.thursday = parseJsonString(element.thursday)
          element.friday = parseJsonString(element.friday)
          element.saturday = parseJsonString(element.saturday)
          element.sunday = parseJsonString(element.sunday)
          element.className = element.className
          element.section_id = element.section_id
        })
        // Now the parsedResponse contains the parsed JSON objects
        const fresponse = response.filter(
          x => x.section_id === sectionDetails.id
        )
        // Set the parsed response into 
        // state or use it as needed;
        setTimetableData(fresponse)
        console.log(timetableData)

        // Perform any additional actions, like opening a modal
        handleOpen()
      } catch (error) {
        // Log the error if something goes wrong
        console.error('Error fetching timetable data:', error)
      }
    } catch (error) { }
  }

  return (
    <ThemeProvider theme={theme}>

      <Container
        sx={{
          flexGrow: 1,
          mt: { xs: 2, md: 5 }, // Margin for small and medium screens
          mb: { xs: 5, md: 7 }, // Adjust bottom margin
          paddingBottom: { xs: '80px', md: '80px' },
          // bgcolor: 'background.paper'
        }}>
        {loginStatus.token && (
          <Box>
            {loginStatus.user.staff_type === 'teacher' ||
              loginStatus.user.staff_type === 'admin' ? (
              <div className='table-cover'>
                {/* <h1>Click on Any Section to view Set and View Student</h1> */}
                <TextField
                  fullWidth
                  label='Filter Class'
                  id='fullWidth'
                  value={filter}
                  sx={{ marginBottom: 3, mt: 5 }}
                  onChange={e => setFilter(e.target.value)}
                />
                {filteredUsers.map(item => (
                  <Accordion defaultExpanded key={item.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1-content'
                      id='panel1-header'
                    >
                      <div>
                        {item.productName}
                        <br />
                        <small style={{ color: '#666' }}>
                          {item.productDescription}
                        </small>
                      </div>
  
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.sections.map(getSection => (
                        <div
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: '6px 12px', // Add padding for spacing inside
                            fontSize: '16px', // Font size for the label
                            borderRadius: '16px', // Rounded corners for a smooth appearance
                            border: '2px solid #e4b648', // Border color matching primary color
                            color: '#e4b648', // Text color matches the border
                            backgroundColor: 'transparent', // Transparent background
                            fontWeight: 600, // Slightly bolder text
                            cursor: 'pointer', // Pointer cursor for interactivity
                            transition: 'background-color 0.3s, color 0.3s', // Smooth transition on hover
                            marginLeft: '15px'
                          }}
                          onClick={e => showTimeTable(e, getSection)} // Prevent clicks on the label from triggering delete
                        >
                          {/* Label */}
                          <span>{getSection.section_text}</span>

                          {/* Delete Icon */}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}

                <Drawer
                  anchor='right'
                  open={open}
                  onClose={handleClose}
                  sx={{
                    '& .MuiDrawer-paper': {
                      width: '100%' // Makes the drawer full screen
                    }
                  }}
                >
                  <>
                    <Box>
                      {/* Modal Header (Fixed) */}
                      <Box
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          pt: '15px',
                          color: '#fff',
                          position: 'sticky',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: 0,
                          zIndex: 1000,
                          width: '100%',
                          pb: '15px'
                        }}
                      >
                        <ArrowBack
                          onClick={handleClose}
                          style={{
                            cursor: 'pointer',
                            color: '#ffffff',
                            position: 'absolute',
                            left: 20
                          }}
                        />
                        <Typography variant='h6'>
                          Student List
                        </Typography>
                      </Box>
                      <StudentDetails studentData={timetableData} />
                      {/* Modal Content */}
                    </Box>
                  </>
                  {/* </Box> */}
                </Drawer>
              </div>
            ) : (
              <div className='task-cover'>
                {filteredUsers.map(item => (
                  <>
                    <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                    <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                      <ListItem alignItems='flex-start'>
                        <ListItemText
                          primary={item.taskHeading}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component='span'
                                variant='body2'
                                sx={{
                                  color: 'text.primary',
                                  display: 'inline'
                                }}
                              >
                                {item.taskDescription}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      {item.amount > 0 ? (
                        <Button component='a' href={item.paymentLink}>
                          Pay Rs. {item.amount}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </List>
                    <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                  </>
                ))}
              </div>
            )}
          </Box>
        )}
      </Container>

    </ThemeProvider >
  )
}

StudentManagement.propTypes = {}

export default StudentManagement
