import React from 'react'
import PropTypes from 'prop-types'
import './TeacherDashboard.scss'
import { Grid, Box, Card, CardContent, Typography, Container } from '@mui/material'
import ImageFilter from 'react-image-filter';
import { Link } from 'react-router-dom'
import {
  ListProductApi,
  ListStudentsApiTeacher,
  ListSubjectApi,
  ListTopImageApi
} from '../../services/apis'
import { useAuth } from '../../services/authContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Slider from 'react-slick';
import { BEBase } from '../../constants/app.constant';

const StudentDashboard = () => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [listOfTeacher, setListOfTeacher] = React.useState([])
  const [listOfStudent, setListOfStudent] = React.useState([])
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [listOfSubjects, setListOfSubjects] = React.useState([])
  const [carouselItems, setCarouselItems] = React.useState([])

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#e4b648' },
      warning: { main: '#e4b648' },
      background: {
        default: '#f5f5f5', // Light grey background
        paper: '#e0e0e0' // Slightly darker grey for paper components
      }
    }
  });


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };


  React.useEffect(() => {
    if (loginStatus) {
      const parsedLoginStat = loginStatus
      if (parsedLoginStat.user && parsedLoginStat.user.staff_type === "teacher") {
        const fetchListProducts = async () => {
          try {
            const response = await ListProductApi(parsedLoginStat.user.id)
            setListOfUsers(response)
          } catch (error) {
            console.error('Error fetching users:', error)
          }
        }
        fetchListProducts()
      }

      const fetchListTopImages = async () => {
        try {
          const response = await ListTopImageApi(
            JSON.stringify({
              id: loginStatus.user.id
            })
          )
          console.log('myresp', response)
          setCarouselItems(response)
          console.log(carouselItems)
        } catch (error) {
          console.error('Error fetching subjects:', error)
        }
      }

      fetchListTopImages()

      const fetchListSubjects = async () => {
        try {
          const response = await ListSubjectApi(loginStatus.user.id)
          setListOfSubjects(response)
        } catch (error) {
          console.error('Error fetching subjects:', error)
        }
      }

      fetchListSubjects()

      const fetchListStudent = async () => {
        try {
          const response = await ListStudentsApiTeacher({
            id: loginStatus.user.id
          })
          setListOfStudent(response)
          console.log(listOfTeacher)
        } catch (error) {
          console.error('Error fetching Teacher:', error)
        }
      }

      fetchListStudent()
    }
  }, [setLoginStatus]) // Only depend on setLoginStatus


  const sections = [

    {
      title: "Student Section",
      cardConfig: [
        {
          link: '/getStudentAttendance',
          image: '/img_frontend/attendance (1).png',
          alt: 'Attendance Icon',
          colorOne: [180, 20, 147],
          colorTwo: [139, 0, 139],
          text: listOfStudent.length > 1 ? 'Attendance' : 'Attendance',
          count: null,
        },
        {
          link: '/viewTimetable',
          image: '/img_frontend/bioatt.png',
          alt: 'Timetables Icon',
          colorOne: [135, 153, 160],
          colorTwo: [135, 153, 160],
          text: listOfUsers.length > 1 ? 'Timetables' : 'Timetable',
          count: null,
        },
        {
          link: '/viewHomework',
          image: '/img_frontend/homework.png',
          alt: 'Homework Icon',
          colorOne: [136, 115, 108],
          colorTwo: [7, 60, 77],
          text: listOfSubjects.length > 1 ? 'Homeworks' : 'Homework',
          count: listOfSubjects.length,
        },
        {
          link: '/fees',
          image: '/img_frontend/hand.png',
          alt: 'Fees Icon',
          colorOne: [204, 101, 139],
          colorTwo: [204, 101, 139],
          text: 'Fees',
          count: null,
        },
        {
          link: '/viewDetail',
          image: '/img_frontend/reading.png',
          alt: 'About me Icon',
          colorOne: [204, 101, 139],
          colorTwo: [204, 101, 139],
          text: 'About Me',
          count: null,
        },
      ],
    },
    {
      title: "Miscellaneous Section",
      cardConfig: [
        {
          link: '/noticeManagement',
          image: '/img_frontend/megaphone.png',
          alt: 'Notices Icon',
          colorOne: [255, 99, 71], // Soft tomato red
          colorTwo: [255, 204, 203], // Pale red, light pinkish
          text: listOfTeacher.length > 1 ? 'Notices' : 'Notice',
          count: null,
        },
        {
          link: '/galleryManagement',
          image: '/img_frontend/gallery.png',
          alt: 'Gallery Icon',
          colorOne: [0, 51, 0], // Dark green
          colorTwo: [100, 2, 0], // Slightly lighter dark green
          text: listOfTeacher.length > 1 ? 'Gallery' : 'Gallery',
          count: null,
        },
        {
          link: '/viewResult',
          image: '/img_frontend/homework.png',
          alt: 'Examination Icon',
          colorOne: [108, 160, 280], // Dusty blue
          colorTwo: [108, 160, 180], // Matching dusty blue
          text: listOfTeacher.length > 1 ? 'Examinations' : 'Examinations',
          count: null,
        },
        // {
        //   link: '/ComplaintManagement',
        //   image: '/img_frontend/gavel.png',
        //   alt: 'Complaint Icon',
        //   colorOne: [241, 0, 0], // Red
        //   colorTwo: [200, 50, 50], // Lighter Red
        //   text: listOfTeacher.length > 1 ? 'Complaints' : 'Complaint',
        //   count: null,
        // },
        // {
        //   link: '/ApplicationManagement',
        //   image: '/img_frontend/hand.png',
        //   alt: 'Applications Icon',
        //   colorOne: [255, 171, 19], // Golden yellow
        //   colorTwo: [255, 199, 77], // Matching tone
        //   text: listOfTeacher.length > 1 ? 'Applications' : 'Application',
        //   count: null,
        // },
        {
          link: '/VideoManagement',
          image: '/img_frontend/video.png',
          alt: 'Video Icon',
          colorOne: [126, 0, 213], // Purple tone
          colorTwo: [156, 50, 230], // Matching tone for consistency
          text: listOfTeacher.length > 1 ? 'Videos' : 'Video',
          count: null,
        },
        // {
        //   link: '/StudymaterialManagement',
        //   image: '/img_frontend/autoStories.png',
        //   alt: 'Study Material Icon',
        //   colorOne: [234, 75, 4], // Bright orange
        //   colorTwo: [244, 114, 54], // Lighter orange
        //   text: listOfTeacher.length > 1 ? 'Studymaterials' : 'Studymaterial',
        //   count: null,
        // },
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: 'rgba(213, 213, 213, 1)',
          marginBottom: '57px',
          paddingBottom: '80px'
        }}
        className='TeacherDashboard'
        data-testid='TeacherDashboard'
      >
        {/* Carousel */}
        <Box sx={{ flexGrow: 1, marginBottom: '20px' }}>
          {carouselItems.length === 1 ? (
            <Box
              sx={{
                position: 'relative',
                textAlign: 'center',
                backgroundImage: `url(${BEBase}media/${encodeURIComponent(carouselItems[0].imagePic1)})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '243px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                color: '#fff'
              }}
            >
              <Typography
                variant='h4'
                sx={{ marginBottom: '10px' }}
              >
                {carouselItems[0].title}
              </Typography>
              <Typography variant='subtitle1'>{carouselItems[0].subtitle}</Typography>
            </Box>
          ) : (
            <Slider {...settings}>
              {carouselItems.map((item, index) => (
                <div key={index}>
                  <Box
                    sx={{
                      position: 'relative',
                      textAlign: 'center',
                      backgroundImage: `url(${BEBase}media/${encodeURIComponent(item.imagePic1)})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: '243px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      color: '#fff'
                    }}
                  >
                    <Typography
                      variant='h4'
                      sx={{ marginBottom: '10px' }}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant='subtitle1'>{item.subtitle}</Typography>
                  </Box>
                </div>
              ))}
            </Slider>
          )}
        </Box>

        <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
          {sections.map((section, sectionIndex) => (
            <Box key={sectionIndex} sx={{ flexGrow: 1, padding: '8px' }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  textAlign: 'left',
                  color: '#2c3e50',
                  padding: '10px 20px',
                  fontWeight: '600',
                  letterSpacing: '1px',
                  borderBottom: '2px solid #bdc3c7',
                }}
              >
                {section.title}
              </Typography>

              <Grid container spacing={2} sx={{ marginTop: '16px' }} justifyContent="start" alignItems="center">
                {section.cardConfig.map((card, cardIndex) => (
                  <Grid item xs={4} sm={4} md={3} lg={2} key={cardIndex}>
                    <Link to={card.link} className="linkTag" style={{ textDecoration: 'none' }}>
                      <Card
                        sx={{
                          backgroundColor: '#fcfcfc',
                          color: '#000000',
                          textAlign: 'center',
                          padding: '16px 12px',
                          ':hover': { backgroundColor: '#f5f5f5' },
                          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
                          borderRadius: '8px', // Rounded corners
                          transition: 'all 0.3s ease-in-out',
                          height: {
                            xs: '90px',
                            sm: '110px',
                          },
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <CardContent
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              width: {
                                xs: '35px', // Smaller size for xs screens
                                sm: '50px', // Larger size for sm screens and up
                              },
                              height: {
                                xs: '35px', // Match width for a square aspect ratio on xs
                                sm: '50px', // Match width for sm screens and up
                              },
                              objectFit: 'contain', // Ensures no distortion
                              marginTop: "10px"
                            }}
                          >
                            <ImageFilter
                              image={card.image}
                              filter="duotone"
                              colorOne={card.colorOne}
                              colorTwo={card.colorTwo}
                              alt={card.alt}
                              style={{
                                width: '100%', // Ensures it scales relative to parent container
                                height: 'auto', // Maintains aspect ratio
                              }}

                            />
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: {
                                xs: '11.2px',
                                sm: '14px',
                              },
                              fontWeight: '700',
                              color: '#2c3e50',
                              marginTop: "10px"
                            }
                            }
                          >
                            {card.text}
                            {card.count === null
                              ? ""
                              : (
                                !card.count === 0
                                  ? `(${card.count})`
                                  : `(${card.count})`
                              )
                            }
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Container>


      </div>
    </ThemeProvider>
  )
}

StudentDashboard.propTypes = {}

export default StudentDashboard
