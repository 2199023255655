import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Container,
  Button,
} from "@mui/material";
import { useAuth } from "../../services/authContext";
import { GetStudentDetail } from "../../services/apis";
import { BEBase } from "../../constants/app.constant";

const StudentPersonalDetails = () => {
  const [loading, setLoading] = useState(false);
  const [studentDetails, setStudentDetails] = useState(null);
  const [classDetails, setClassDetails] = useState(null);
  const [error, setError] = useState("");
  const { loginStatus } = useAuth();

  useEffect(() => {
    const fetchStudentDetails = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await GetStudentDetail({ student_id: loginStatus.user.id }); // Call your API function
        const data = response; // Assuming the API returns the data in the desired structure
        setStudentDetails(data.student_details);
        setClassDetails(data.class_details);
      } catch (err) {
        setError(err.message || "An error occurred while fetching student details.");
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, [loginStatus.user.id]);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ py: "20" }}>
        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        flexGrow: 1,
        mt: { xs: 2, md: 5 }, // Margin for small and medium screens
        mb: { xs: 5, md: 7 }, // Adjust bottom margin
        paddingBottom: { xs: "80px", md: "80px" },
      }}>
      {studentDetails && (
        <>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            Student Details
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 3 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Name:</TableCell>
                  <TableCell>{studentDetails.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Phone:</TableCell>
                  <TableCell>{studentDetails.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Email:</TableCell>
                  <TableCell>{studentDetails.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Gender:</TableCell>
                  <TableCell>{studentDetails.gender}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>DOB:</TableCell>
                  <TableCell>{studentDetails.dob}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Address:</TableCell>
                  <TableCell>{studentDetails.address}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {classDetails && (
        <>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            Class Details
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 3 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Class Name:</TableCell>
                  <TableCell>{classDetails.class_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Section Name:</TableCell>
                  <TableCell>{classDetails.section_name}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h6" gutterBottom>
            Subjects
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>Subject Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>Subject Code</TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>Type</TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>Syllabus</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classDetails.subjects.map((subject, index) => (
                  <TableRow key={index}>
                    <TableCell>{subject.subjectName}</TableCell>
                    <TableCell>{subject.subjectCode}</TableCell>
                    <TableCell>{subject.subjectType}</TableCell>
                    <TableCell>
                      <a href={`${BEBase}media/${subject.syllabus}`} target="_blank" rel="noopener noreferrer">
                        <Button variant="contained" color="success">
                          View
                        </Button>
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  );
};

export default StudentPersonalDetails;
