import React from 'react'
import './StudentHomeWorkDetails.scss'
import { useAuth } from '../../services/authContext'
import {
  Box,
  Fab,
  TextField,
  Button,
  Modal,
  Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  ListProductApi,
  ListClassRelatedToSectionApi,
  CreateStudentHomeworkApi,
  ListStudentHomeworkApi,
  DeleteStudentHomeworkApi,
  UpdateStudentHomeworkApi
} from '../../services/apis'

import CloseIcon from '@mui/icons-material/Close'
import { BEBase } from '../../constants/app.constant'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Autocomplete} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import CryptoJS from 'crypto-js';
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../componentConst';


const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23; // Replace with a secure key

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString); // Parse the JSON string
  } catch (error) {
    console.error('Decryption error:', error);
    return null; // Return null if decryption fails
  }
};



const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vW',
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH'
}


const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%', // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH',
  '@media (max-width: 600px)': {
    // Adjust the breakpoint as needed
    width: '100%' // Width for mobile devices
  }
}



const StudentHomeWorkDetails = props => {
  const [timetableData, setTimetableData] = React.useState({})
  const [addedClassFromSection, setAddedClassFromSection] = React.useState([])
  const [selectedClasses, setSelectedClasses] = React.useState({}) // Stores selected subjects
  const [startTimes, setStartTimes] = React.useState({}) // Stores selected start times
  const [endTimes, setEndTimes] = React.useState({}) // Stores selected end times
  const [teacher, setTeacher] = React.useState([]) // Stores all teachers
  const [selectedTeachers, setSelectedTeachers] = React.useState({}) // Stores selected teachers
  const { loginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [amount, setAmount] = React.useState('')
  const [paymentLink, setPaymentLink] = React.useState('')
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [className, setclassName] = React.useState('')
  const [classList, setCLassList] = React.useState([])
  const [imgOpen, setImgOpen] = React.useState(false)
  const [selectedID, setSelectedID] = React.useState('')
  const [openedImage, setOpenedImage] = React.useState('')
  const [syllabus, setSyllabus] = React.useState()
  const [changeImgFlag, setChangeImgFlag] = React.useState('')
  const [imageCacheKey, setImageCacheKey] = React.useState(Date.now())
  const [homeworkDate, setHomeworkDate] = React.useState('')
  const [product, setProduct] = React.useState('')
  const [subject, setSubject] = React.useState('')
  const [heading, setHeading] = React.useState('')
  const [content, setContent] = React.useState('')
  const [homeworkSheet, setHomeworkSheet] = React.useState(null)
  const [fetchedSubjects, setFetchedSubjects] = React.useState([])

  const [selectedDate, setSelectedDate] = React.useState(
    dayjs().format('YYYY-MM-DD')
  )

  React.useEffect(() => {
    if (listApiFlag && loginStatus.user && (loginStatus.user.staff_type === "teacher" || loginStatus.user.staff_type === "admin")) {
      const fetchListProducts = async () => {
        try {
          const encryptedCreds = localStorage.getItem('loginCred')

          if (!encryptedCreds) {
            throw new Error('No login credentials found in localStorage')
          }

          const decryptedCreds = decryptData(encryptedCreds)

          if (!decryptedCreds || !decryptedCreds.token) {
            throw new Error(
              'Failed to retrieve valid token from decrypted credentials'
            )
          }

          const authToken = JSON.stringify(decryptedCreds)
          const response = await ListStudentHomeworkApi({
            homeworkDate: selectedDate,
            id: loginStatus.user.id
          })
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      const fetchListClasses = async () => {
        try {
          const response = await ListProductApi()
          response.forEach(element => {
            element.title = element.productName
          })
          setCLassList(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
      fetchListClasses()
    }
  }, [loginStatus])

  React.useEffect(() => {
    if (selectedDate) {
      // Call the API whenever selectedDate changes
      const encryptedCreds = localStorage.getItem('loginCred')

      if (!encryptedCreds) {
        throw new Error('No login credentials found in localStorage')
      }

      const decryptedCreds = decryptData(encryptedCreds)

      if (!decryptedCreds || !decryptedCreds.token) {
        throw new Error(
          'Failed to retrieve valid token from decrypted credentials'
        )
      }

      const fetchListProducts = async () => {
        try {
          const encryptedCreds = localStorage.getItem('loginCred')

          if (!encryptedCreds) {
            throw new Error('No login credentials found in localStorage')
          }

          const decryptedCreds = decryptData(encryptedCreds)

          if (!decryptedCreds || !decryptedCreds.token) {
            throw new Error(
              'Failed to retrieve valid token from decrypted credentials'
            )
          }

          const authToken = JSON.stringify(decryptedCreds)
          const response = await ListStudentHomeworkApi({
            homeworkDate: selectedDate,
            id: loginStatus.user.id
          })
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }
      fetchListProducts()
    }
  }, [selectedDate])

  // Form Submission Handler
  const handleUpdateTask = async () => {
    try {
      // Log values for debugging
      console.log('selectedID:', selectedID)
      console.log('subject:', subject.subjectName)
      console.log('homeworkDate:', homeworkDate)
      console.log('heading:', heading)
      console.log('content:', content)
      console.log('homeworkSheet:', homeworkSheet)

      // Check if required fields are provided
      if (
        !selectedID ||
        !subject ||
        !homeworkDate ||
        !heading ||
        !content ||
        !homeworkSheet
      ) {
        return
      }

      // Create FormData object to hold form data
      const formData = new FormData()
      formData.append('homework_id', selectedID)
      formData.append('subjectName', subject)
      formData.append('homeworkDate', homeworkDate)
      formData.append('heading', heading)
      formData.append('content', content)
      formData.append('homeworkSheet', homeworkSheet)

      // Call the UpdateStudentHomeworkApi function
      const response = await UpdateStudentHomeworkApi(formData)

      if (response) {
        console.log('Homework updated successfully:', response)
        handleClose() // Close the modal
        window.location.reload() // Reload the page to reflect changes
      }
    } catch (error) {
      console.error('Error updating homework:', error)
    }
  }

  const filteredUsers = listOfUsers.filter(user => {
    const classMatches = user.product_id == props.homeWorkData[0]?.section_id
    const userHeading = user.heading
      .toLowerCase()
      .includes(filter.toLowerCase())
    return classMatches && userHeading
  })


  const [open, setOpen] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)
  const handleOpen = username => {
    console.log('gbcsxz', username, username.subject_id)
    setSelectedUser(username)
    setHomeworkDate(username.homeworkDate)
    setSubject(username.subject_id)
    setHeading(username.heading)
    setContent(username.content)
    setHomeworkSheet(username.homeworkSheet)
    setSelectedID(username.id)
    setSyllabus(username.syllabus)
    setOpen(true)
  }

  const handleOpenCreate = () => {
    setOpenCreate(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenCreate(false)
  }

  const handleCreateHomework = async () => {
    try {
      const formData = new FormData()
      const encryptedCreds = localStorage.getItem('loginCred')

      if (!encryptedCreds) {
        throw new Error('No login credentials found in localStorage')
      }

      const decryptedCreds = decryptData(encryptedCreds)

      if (!decryptedCreds || !decryptedCreds.token) {
        throw new Error(
          'Failed to retrieve valid token from decrypted credentials'
        )
      }

      const authToken = JSON.stringify(decryptedCreds)
      formData.append('homeworkdate', homeworkDate)
      formData.append('product_id', props.homeWorkData[0]?.section_id)
      formData.append('subject_id', subject)
      formData.append('heading', heading)
      formData.append('content', content)
      formData.append('homeworkSheet', homeworkSheet)
      formData.append('adminToken', JSON.parse(authToken).token)

      // Log form data for debugging
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`)
      }

      const response = await CreateStudentHomeworkApi(formData) // Assuming this is your API call function
      console.log('Homework created successfully:', response)

      // Refresh or update UI
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating homework:', error)
    }
  }

  const handleUpdateImageTask = async imagePath => {
    console.log(imagePath)
    const formData = new FormData()
    formData.append('changeImgFlag', changeImgFlag)
    formData.append('imagePath', imagePath)
  }

  const handleDeleteProduct = async deleteId => {
    try {
      const formData = new FormData()
      formData.append('id', deleteId)

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteStudentHomeworkApi(formData)

      console.log('Product deleted successfully:', response)
      handleClose()
    } catch (error) {
      console.error('Error deleting Product:', error)
    }
  }

  React.useEffect(() => {
    if (Array.isArray(props.homeWorkData)) {
      setTimetableData(props.homeWorkData)
      classFromSectionsApi(props.homeWorkData[0]?.section_id)
    }
  }, [props.homeWorkData])

  const classFromSectionsApi = async sectionId => {
    const body = { id: sectionId }

    try {
      const classFromSection = await ListClassRelatedToSectionApi(body)
      console.log('getclass from ', classFromSection)
      const getFetchedSubjects = classFromSection[0].subject.map(
        (arr) => {
          return arr.subjectName
        }
      )
      setFetchedSubjects(getFetchedSubjects)
      setAddedClassFromSection(classFromSection)
    } catch (error) {
      console.error('Error fetching class data:', error)
    }
  }

  return (
    <div
      className='StudentHomeWorkDetails'
      data-testid='StudentHomeWorkDetails'
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {Array.isArray(timetableData) && timetableData.length > 0 ? (
          <>
            {loginStatus.user.staff_type === "teacher" || loginStatus.user.staff_type === "admin" ? (
              <div className='table-cover'>
                <Box sx={{ position: 'absolute', bottom: 76, right: 16 }}>
                  <Fab
                    color='primary'
                    aria-label='add'
                    onClick={handleOpenCreate}
                    sx={{ position: 'fixed', right: '30px', bottom: '70px' }}
                  >
                    <AddIcon />
                    {/*timetableData[0]?.section_id || 'Section ID not available'*/}
                  </Fab>
                </Box>
                {/* Date Picker */}
                <DatePicker
                  label='Select Date'
                  fullWidth
                  value={
                    selectedDate
                      ? dayjs(selectedDate).isValid()
                        ? dayjs(selectedDate)
                        : null
                      : null
                  } // Ensure valid dayjs object
                  onChange={newValue => {
                    // Only set the selected date if the newValue is valid
                    const formattedDate = dayjs(newValue)
                    if (formattedDate.isValid()) {
                      setSelectedDate(formattedDate.format('YYYY-MM-DD')) // Store in 'YYYY-MM-DD' format
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      sx={{ marginBottom: 3, width: '100%' }}
                    />
                  )}
                />
                <TextField
                  fullWidth
                  label='Filter Class'
                  id='fullWidth'
                  value={filter}
                  sx={{ marginBottom: 3 }}
                  onChange={e => setFilter(e.target.value)}
                />
                {filteredUsers
                  .filter(
                    (item, index, self) =>
                      index ===
                      self.findIndex(t => t.className === item.className)
                  )
                  .map(item => (
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>Homework Heading</Th>
                          <Th>Homework Content</Th>
                          <Th>Subject</Th>
                          {/*<Th>Class Pics</Th>*/}
                          <Th>Actions</Th>
                          <Th>Details</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredUsers.map(users => (
                          <>
                            {users.className === item.className ? (
                              <Tr key={users.id}>
                                <Td>{users.heading}</Td>
                                <Td>{users.content}</Td>
                                <Td>{users.subject_id}</Td>
                                {/*<Td>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic1)}>1</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic2)}>2</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic3)}>3</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic4)}>4</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic5)}>5</Button>
                                </Td>*/}
                                <Td>
                                  <Button
                                    variant='contained'
                                    className='btn'
                                    onClick={() => handleOpen(users)}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    variant='contained'
                                    color='error'
                                    className='btn'
                                    onClick={() =>
                                      handleDeleteProduct(users.id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </Td>
                                <Td>
                                  <Button
                                    component='a'
                                    variant='contained'
                                    color='success'
                                    href={
                                      BEBase +
                                      'media/' +
                                      users.homeworkSheet +
                                      `?_=${new Date().getTime()}`
                                    }
                                    target='_blank'
                                  >
                                    Check
                                  </Button>
                                </Td>
                              </Tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </Tbody>
                    </Table>
                  ))}
                <Modal
                  fullscreen
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                  sx={{ overflow: 'auto' }}
                >
                  <Box sx={{ ...style, position: 'relative' }}>
                    <CloseIcon
                      onClick={handleClose}
                      style={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        cursor: 'pointer'
                      }}
                    />
                    <Typography
                      id='modal-modal-title'
                      variant='h6'
                      component='h2'
                      sx={{ marginBottom: 3 }}
                    >
                      Update Homework
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      label='Update Homework Date'
                      type='date'
                      className='register-form'
                      value={homeworkDate}
                      onChange={e => setHomeworkDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      sx={{ marginBottom: 3 }}
                    />

                    <Autocomplete
                      id='free-solo-demo-class'
                      options={fetchedSubjects || []}
                      value={subject}
                      onChange={(event, newValue) => setSubject(newValue)}
                      renderInput={params => (
                        <TextField
                          fullWidth
                          {...params}
                          label='Select Class'
                          required
                          error={!subject}
                        />
                      )}
                      sx={{ marginBottom: 3 }}
                    />

                    <TextField
                      fullWidth
                      required
                      label='Update Heading'
                      className='register-form'
                      value={heading}
                      onChange={e => setHeading(e.target.value)}
                      sx={{ marginBottom: 3 }}
                    />
                    <TextField
                      fullWidth
                      required
                      label='Update Content'
                      className='register-form'
                      multiline
                      rows={4}
                      value={content}
                      onChange={e => setContent(e.target.value)}
                      sx={{ marginBottom: 3 }}
                    />
                    <label>Homework Sheet</label>
                    <TextField
                      fullWidth
                      required
                      className='register-form'
                      type='file'
                      onChange={e => setHomeworkSheet(e.target.files[0])}
                      sx={{ marginBottom: 3 }}
                    />
                    <Button
                      variant='contained'
                      className='btn'
                      onClick={handleUpdateTask}
                      disabled={
                        !homeworkDate ||
                        !subject ||
                        !heading ||
                        !content ||
                        !homeworkSheet
                      }
                    >
                      Update Homework
                    </Button>
                  </Box>
                </Modal>

                <Modal
                  fullscreen
                  open={imgOpen}
                  onClose={() => setImgOpen(false)}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={{ ...ImgStyle, position: 'relative' }}>
                    <CloseIcon
                      onClick={() => setImgOpen(false)}
                      style={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        cursor: 'pointer'
                      }}
                    />
                    <Box>
                      <img
                        src={`${BEBase}media/${openedImage}?cache=${imageCacheKey}`}
                        style={{ width: '100%' }}
                      />
                    </Box>
                    <TextField
                      fullWidth
                      required
                      className='register-form'
                      type='file'
                      sx={{ marginBottom: 3 }}
                      onChange={e => {
                        const file = e.target.files[0]
                        if (file) {
                          setChangeImgFlag(file)
                        }
                      }}
                    />
                    <Button
                      variant='contained'
                      className='btn'
                      onClick={() => {
                        handleUpdateImageTask(openedImage)
                        setImgOpen(false) // Close the modal after the update
                      }}
                    >
                      Change Image
                    </Button>
                  </Box>
                </Modal>

                <Modal
                  fullscreen
                  open={openCreate}
                  onClose={handleClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                  sx={{ overflow: 'auto' }}
                >
                  <Box sx={{ ...style, position: 'relative' }}>
                    <CloseIcon
                      onClick={handleClose}
                      style={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        cursor: 'pointer'
                      }}
                    />
                    <Typography
                      id='modal-modal-title'
                      variant='h6'
                      component='h2'
                      sx={{ marginBottom: 3 }}
                    >
                      Create New Homework
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      label='Homework Date'
                      type='date'
                      className='register-form'
                      value={homeworkDate}
                      onChange={e => setHomeworkDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      sx={{ marginBottom: 3 }}
                    />
                    <Autocomplete
                      id='subject-autocomplete'
                      options={addedClassFromSection[0]?.subject || []} // Pass the full subject objects
                      getOptionLabel={
                        option => option?.subjectName || '' // Display the subject name in the dropdown
                      }
                      onChange={(event, newValue) =>
                        setSubject(newValue.subjectName || null)
                      }
                      renderInput={params => (
                        <TextField
                          fullWidth
                          {...params}
                          label='Select Subject'
                        />
                      )}
                    />

                    <TextField
                      fullWidth
                      required
                      label='Heading'
                      className='register-form'
                      value={heading}
                      onChange={e => setHeading(e.target.value)}
                      sx={{ marginBottom: 3 }}
                    />
                    <TextField
                      fullWidth
                      required
                      label='Content'
                      className='register-form'
                      multiline
                      rows={4}
                      value={content}
                      onChange={e => setContent(e.target.value)}
                      sx={{ marginBottom: 3 }}
                    />
                    <label>Homework Sheet</label>
                    <TextField
                      fullWidth
                      required
                      className='register-form'
                      type='file'
                      onChange={e => setHomeworkSheet(e.target.files[0])}
                      sx={{ marginBottom: 3 }}
                    />
                    <Button
                      variant='contained'
                      className='btn'
                      onClick={handleCreateHomework}
                      disabled={
                        !homeworkDate ||
                        !subject ||
                        !heading ||
                        !content ||
                        !homeworkSheet
                      }
                    >
                      Create Homework
                    </Button>
                  </Box>
                </Modal>
              </div>
            ) : (
              <></>
            )}</>
        ) : (
          <>No data available</>
        )}
      </LocalizationProvider>
    </div>
  )
}

StudentHomeWorkDetails.propTypes = {}

export default StudentHomeWorkDetails
