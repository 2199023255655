import React from 'react'
import PropTypes from 'prop-types'
import './CreateResultManagement.scss'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useAuth } from '../../services/authContext'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Fab,
  Select,
  MenuItem,
  Card,
  CardMedia,
  CardContent,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Drawer,
  SelectChangeEvent,
  Autocomplete,
  Popover,
  Menu,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Fade,
  Paper,
  useMediaQuery,
  listClasses,
  TextField
} from '@mui/material'
import {
  ListProductApi,
  CreateTaskApi,
  ListTaskApi,
  CreateCourseApi,
  UpdateCourseApi,
  DeleteCourseApi,
  UpdateCourseImageApi,
  DeleteSectionApi,
  ListExamApi,
  ListCreateResultApi,
  ListSubjectApi,
  ListSubjectFromClassNameIdApi,
  CreateCreateResultApi,
  UpdateAddMarksApi,
  UpdateCreateResultApi,
  DeleteCreateResultApi,
  UpdatePublishResultApi
} from '../../services/apis'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CreateResultManagementDetails from '../CreateResultManagementDetails/CreateResultManagementDetails'
import { BEBase } from '../../constants/app.constant'

const options = ['Update', 'Delete']

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%", // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH',
  '@media (max-width: 600px)': { // Adjust the breakpoint as needed
    width: '100%', // Width for mobile devices
  }
};


const CreateResultmanagement = () => {
  const ITEM_HEIGHT = 48
  const { loginStatus, setLoginStatus } = useAuth()
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [drawerTableOpen, setDrawerTableOpen] = React.useState(false)
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [reason, setReason] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [updateOpen, setUpdateOpen] = React.useState(false)
  const [listOfProduct, setListOfProduct] = React.useState([])
  const [listOfExam, setListOfExam] = React.useState([])
  const [listOfSubject, setListOfSubject] = React.useState([])
  const [anchorElAccordion, setAnchorElAccordion] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedID, setSelectedID] = React.useState('')
  const [subjectName, setSubjectName] = React.useState('')
  const [detailsData, setDetatailsData] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [subjectId, setSubjectId] = React.useState('')
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false)
  const [selectedSection, setSelectedSection] = React.useState('')
  const [selectedClassName, setSelectedClassName] = React.useState('')
  const [selectedClassId, setSelectedClassId] = React.useState('')
  const [examName, setExamName] = React.useState('')
  const [marks, setMarks] = React.useState('')
  const [examId, setExamId] = React.useState(0)
  const [selectedSectionId, setSelectedSectionId] = React.useState(0)
  const [formData, setFormData] = React.useState({
    exam: '',
    class: '',
    subject: '',
    marks: ''
  })
  const [openMenuId, setOpenMenuId] = React.useState(null)

  const theme = createTheme({
    palette: {
      primary: {
        main: '#284d84' // blue color
      },
      secondary: {
        main: '#EC4899' // Pink color for the check icons
      }
    },
    typography: {
      fontFamily: 'Arial, sans-serif'
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      }
    }
  })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const toggleDrawer = open => () => {
    setDrawerOpen(open)
  }

  const handleToggleDrawerTable = (open, data) => () => {
    console.log('dataaaa', data)
    setDetatailsData(data)
    setDrawerTableOpen(open)
  }

  const toggleDrawerTable = open => () => {
    setDrawerTableOpen(open)
  }

  const filteredUsers = listOfUsers.filter(user =>
    user.className.toLowerCase().includes(filter.toLowerCase())
  )

  const fetchListSubject = async productName => {
    try {
      const response = await ListSubjectFromClassNameIdApi({
        id: loginStatus.user.id,
        className: productName
      })
      setListOfSubject(response)
    } catch (error) {
      console.error('Error fetching exams:', error)
    }
  }

  const handleFabClick = event => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleAccordianClick = (event, selectID) => {
    event.stopPropagation()
    console.log('ancholElAccordian', event, event.currentTarget, selectID)
    setAnchorElAccordion(event.currentTarget) // Set the anchor for the accordion menu
    setOpenMenuId(selectID) // Set the id for the menu being opened
  }

  const handleUpdateOpen = users => {
    console.log('my users', users)
    setSelectedID(users.uid)
    setMarks(users.totalMarks)
    setUpdateOpen(true)
  }

  const handleUpdateTask = async () => {
    try {
      const formData = {
        id: selectedID,
        totalMarks: marks
      }

      const response = await UpdateCreateResultApi(formData)
      window.location.reload()
    } catch (error) {
      console.error('Error creating Product:', error)
    }
  }

  const handleDeleteProduct = async () => {
    try {
      const formData = new FormData()
      formData.append('id', selectedID)

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteCreateResultApi(formData)

      console.log('Product deleted successfully:', response)
      handleClose()
    } catch (error) {
      console.error('Error deleting Product:', error)
    }
  }

  const handleDeleteProductModal = async deleteId => {
    setSelectedID(deleteId)
    setDeleteProductOpen(true)
  }

  const handleCloseMe = () => {
    setAnchorEl(null)
  }

  const handleCloseMeAccordion = () => {
    setAnchorElAccordion(null)
    setOpenMenuId(null)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      const request_data = {
        exam: examId,
        subject: subjectId,
        className: selectedClassId,
        section: selectedSectionId,
        totalMarks: marks,
        sectionName: selectedSection,
        id: loginStatus.user.id
      }
      console.log('getreq', request_data)
      const response = await CreateCreateResultApi(request_data)

      console.log('Create Result created successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating notice:', error)
    }
  }

  const handlePublishSubmit = async (event, id, status, users) => {
    event.preventDefault()
    console.log('getuser', users, status);
    try {
      const request_data = JSON.stringify({
        id: users.uid,
        status: users.status === 'unpublished' ? 'published' : 'unpublished'
      })

      const response = await UpdatePublishResultApi(request_data)

      console.log('Result Published successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating notice:', error)
    }
  }

  const handleClose = e => {
    e.preventDefault()
    setUpdateOpen(false)
    setOpen(false) // Close the modal
  }

  const handleChange = event => {
    const { name, value } = event.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSectionClick = section => {
    setSelectedSectionId(section.sectionName.id)
    setSelectedSection(section.sectionName.section_text)
    setSelectedClassName(section.productName.name)
    setSelectedClassId(section.productName.id)
    setDrawerOpen(false) // Close the drawer after selection
    fetchListSubject(section.productName.name)
  }

  React.useEffect(() => {
    if (listApiFlag && loginStatus.user && loginStatus.user.staff_type === "admin" || loginStatus.user.staff_type === "teacher") {
      const fetchListCreateResult = async () => {
        try {
          const response = await ListCreateResultApi({
            id: loginStatus.user.id
          })
          setListOfUsers(response)
        } catch (error) {
          console.error('Error fetching users:', error)
        }
      }

      const fetchListProduct = async () => {
        try {
          const response = await ListProductApi({ id: loginStatus.user.id })
          setListOfProduct(response)
        } catch (error) {
          console.error('Error fetching products:', error)
        }
      }

      const fetchListExam = async () => {
        try {
          const response = await ListExamApi({ id: loginStatus.user.id })
          setListOfExam(response)
        } catch (error) {
          console.error('Error fetching exams:', error)
        }
      }

        // Run all fetch operations
        ; (async () => {
          await Promise.all([
            fetchListCreateResult(),
            fetchListProduct(),
            fetchListExam()
          ])
          setListApiFlag(false) // Update flag only once
        })()
    }
  }, [listApiFlag, loginStatus])

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          flexGrow: 1,
          mt: { xs: 2, md: 5 }, // Margin for small and medium screens
          mb: { xs: 5, md: 7 }, // Adjust bottom margin
          paddingBottom: { xs: '80px', md: '80px' },
          // bgcolor: 'background.paper'
        }}>
        {loginStatus.token && (
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              sm: 'center',
              lg: 'center',
              xl: 'center',
            }}
          >
            <Modal
              open={deleteProductOpen}
              onClose={() => setDeleteProductOpen(false)}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={{ ...ImgStyle, position: 'relative' }}>
                <CloseIcon
                  onClick={() => setDeleteProductOpen(false)}
                  style={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    cursor: 'pointer'
                  }}
                />
                <h3>Are you Sure You want to delete this Result</h3>
                <Button
                  variant='contained'
                  className='btn'
                  onClick={() => {
                    handleDeleteProduct()
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant='contained'
                  className='btn'
                  onClick={() => setDeleteProductOpen(false)}
                >
                  No
                </Button>
              </Box>
            </Modal>
            <Modal
              open={open}
              onClose={() => setOpen(false)} // Close modal when clicking outside
              aria-labelledby='add-complaint-reason'
              aria-describedby='add-complaint-reason-description'
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: isMobile ? '350px' : '500px',
                  backgroundColor: '#fff',
                  padding: 3,
                  borderRadius: 2,
                  boxShadow: 24
                }}
              >
                <Typography
                  id='add-complaint-reason'
                  variant='h6'
                  component='h1'
                  sx={{ marginBottom: 3, fontWeight: 'bold' }}
                >
                  Create Result
                </Typography>

                <CloseIcon
                  onClick={handleClose}
                  sx={{
                    marginLeft: isMobile ? '280px' : '430px',
                    marginTop: isMobile ? '-110px' : '-110px'
                  }}
                />

                <Box
                  component='form'
                  onSubmit={handleSubmit}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Autocomplete
                    id='free-solo-demo-class'
                    options={listOfExam}
                    getOptionLabel={option => option.exam}
                    value={
                      listOfExam.find(exam => exam.exam === examName) || null
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setExamName(newValue.exam)
                        setExamId(newValue.id)
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        fullWidth
                        {...params}
                        label='Select Exam'
                        required
                      />
                    )}
                    sx={{ marginBottom: isMobile ? '10px' : '20px' }}
                  />

                  <TextField
                    value={selectedClassName}
                    onClick={toggleDrawer(true)}
                    onChange={event =>
                      setSelectedClassName(event.target.value)
                    }
                    label='Class Name'
                    InputProps={{
                      readOnly: true
                    }}
                    sx={{
                      cursor: 'pointer',
                      marginBottom: isMobile ? '10px' : '20px'
                    }}
                  />

                  <TextField
                    value={selectedSectionId}
                    onChange={event =>
                      setSelectedSectionId(event.target.value)
                    }
                    label='Section Id'
                    InputProps={{
                      readOnly: true
                    }}
                    sx={{
                      display: 'none', // Hides the TextField completely
                      cursor: 'pointer',
                      marginBottom: isMobile ? '10px' : '20px'
                    }}
                  />

                  <TextField
                    value={selectedSection}
                    onChange={event => setSelectedSection(event.target.value)}
                    label='Section Name'
                    InputProps={{
                      readOnly: true
                    }}
                    sx={{
                      cursor: 'pointer',
                      marginBottom: isMobile ? '10px' : '20px'
                    }}
                  />

                  <Autocomplete
                    id='free-solo-demo-class'
                    options={listOfSubject}
                    getOptionLabel={option => option.subjectName}
                    value={
                      listOfSubject.find(
                        exam => exam.subjectName === subjectName
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSubjectName(newValue.subjectName)
                        setSubjectId(newValue.id)
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        fullWidth
                        {...params}
                        label='Select Subject'
                        required
                      />
                    )}
                    sx={{ marginBottom: isMobile ? '10px' : '20px' }}
                  />
                  <TextField
                    required
                    label='Enter total marks (Selected Subject)'
                    name='marks'
                    type='number'
                    value={marks}
                    onChange={event => setMarks(event.target.value)} // Use event.target.value
                    fullWidth
                    variant='outlined'
                    sx={{ marginBottom: isMobile ? '10px' : '20px' }}
                  />

                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='warning'
                    sx={{ marginTop: 2 }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Modal>

            {/* update model */}
            <Modal
              open={updateOpen}
              onClose={() => setUpdateOpen(false)} // Close modal when clicking outside
              aria-labelledby='add-complaint-reason'
              aria-describedby='add-complaint-reason-description'
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: isMobile ? '350px' : '500px',
                  backgroundColor: '#fff',
                  padding: 3,
                  borderRadius: 2,
                  boxShadow: 24
                }}
              >
                <Typography
                  id='add-complaint-reason'
                  variant='h6'
                  component='h1'
                  sx={{ marginBottom: 3, fontWeight: 'bold' }}
                >
                  Update Result
                </Typography>

                <CloseIcon
                  onClick={handleClose}
                  sx={{
                    marginLeft: isMobile ? '280px' : '430px',
                    marginTop: isMobile ? '-110px' : '-110px'
                  }}
                />

                <Box
                  component='form'
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <TextField
                    required
                    label='Enter total marks (Selected Subject)'
                    name='marks'
                    type='number'
                    value={marks}
                    onChange={event => setMarks(event.target.value)} // Use event.target.value
                    fullWidth
                    variant='outlined'
                    sx={{ marginBottom: isMobile ? '10px' : '20px' }}
                  />

                  <Button
                    onClick={handleUpdateTask}
                    fullWidth
                    variant='contained'
                    color='warning'
                    sx={{ marginTop: 2 }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
        )}


        <Grid
          spacing={2}
          sx={{
            width: '100%',
          }}
        >
          <TextField
            fullWidth
            label='Filter Class'
            id='fullWidth'
            value={filter}
            sx={{ marginBottom: 3, marginTop: 2 }}
            onChange={e => setFilter(e.target.value)}
          />
          {filteredUsers
            .filter(
              (item, index, self) =>
                index === self.findIndex(t => t.className === item.className)
            )
            .map(item => (
              <Accordion sx={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                  sx={{
                    width: '96%'
                  }}
                >
                  {item.className}
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Section</Th>
                        <Th>Subject</Th>
                        <Th>Exam</Th>
                        <Th>Maximum Marks </Th>
                        <Th>Actions</Th>
                        <Th>Add Marks</Th>
                        <Th>Publish Result</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredUsers.map(users => (
                        <>
                          {users.className === item.className ? (
                            <Tr key={users.id}>
                              <Td>{users.section_name}</Td>
                              <Td>{users.subject}</Td>
                              <Td>{users.exam}</Td>
                              <Td>{users.totalMarks}</Td>
                              {/*<Td>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic1)}>1</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic2)}>2</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic3)}>3</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic4)}>4</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic5)}>5</Button>
                                </Td>*/}
                              <Td>
                                <Button
                                  variant='contained'
                                  className='btn'
                                  onClick={() => handleUpdateOpen(users)}
                                >
                                  Update
                                </Button>
                                <Button
                                  variant='contained'
                                  color='error'
                                  className='btn'
                                  onClick={() =>
                                    handleDeleteProductModal(users.uid)
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                              <Td>
                                <Button
                                  variant='contained'
                                  color='success'
                                  onClick={handleToggleDrawerTable(true, users)}
                                >
                                  Add
                                </Button>
                              </Td>
                              <Td>
                                <Button
                                  variant='contained'
                                  color='warning'
                                  onClick={(e) => handlePublishSubmit(e, users.id, users.status, users)}
                                >
                                  {users.status === 'unpublished' ? "Publish" : "Unpublish"}
                                </Button>
                              </Td>
                            </Tr>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </Tbody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ))}
        </Grid>

      </Container>

      <Fab
        color='primary'
        sx={{
          marginBottom: '55px',
          marginRight: '20px',
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1000
        }}
        onClick={handleFabClick}
      >
        {/* Add your icon here, for example: */}
        <AddIcon />
      </Fab>

      <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%' // Makes the drawer full screen
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
          role='presentation'
        >
          {/* Header */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ccc',
              pb: 2,
              mb: 2,
              backgroundColor: '#0d2238', // Yellow background color
              color: '#ffffff',
              padding: '16px' // Add padding for better spacing
            }}
          >
            <IconButton onClick={toggleDrawer(false)}>
              <ArrowBackIcon sx={{ color: '#ffffff' }} />
            </IconButton>
            <Typography
              variant='h6'
              sx={{
                flexGrow: 1,
                textAlign: 'center'
              }}
            >
              Add Class
            </Typography>
            <Box sx={{ width: 48 }} /> {/* Spacer to balance header layout */}
          </Box>

          {/* Content */}
          <Container>
            {listOfProduct.map((item, index) => (
              <Accordion defaultExpanded key={item.id}>
                <AccordionSummary
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <div>
                    {item.productName}
                    <br />
                    <small style={{ color: '#666' }}>
                      {item.productDescription}
                    </small>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {item.sections.map(getSection => (
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        padding: '6px 12px',
                        fontSize: '16px',
                        borderRadius: '16px',
                        border: '2px solid #e4b648',
                        color: '#e4b648',
                        backgroundColor: 'transparent',
                        fontWeight: 600,
                        cursor: 'pointer',
                        transition: 'background-color 0.3s, color 0.3s',
                        marginLeft: '15px'
                      }}
                      onClick={() =>
                        handleSectionClick({
                          productName: {
                            id: item.id,
                            name: item.productName
                          },
                          sectionName: {
                            id: getSection.id,
                            section_text: getSection.section_text
                          }
                        })
                      }
                    >
                      <span>{getSection.section_text}</span>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </Box>
      </Drawer>


      {/* result details drawer */}
      <Drawer
        anchor="right"
        open={drawerTableOpen}
        onClose={handleToggleDrawerTable(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%', // Makes the drawer full screen
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {/* Fixed Header */}
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1100, // Ensure it stays above other elements
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #ccc',
            backgroundColor: '#0d2238',
            color: '#ffffff',
            padding: '16px',
          }}
        >
          <IconButton onClick={toggleDrawerTable(false)}>
            <ArrowBackIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              textAlign: 'center',
            }}
          >
            Add Scores
          </Typography>
          <Box sx={{ width: 48 }} /> {/* Spacer to balance header layout */}
        </Box>

        {/* Content with Padding */}
        <Box
          sx={{
            flex: 1, // Allows content to fill the remaining space
            overflow: 'auto',
            paddingTop: '80px', // Space for the fixed header
            paddingBottom: '80px', // Space for the fixed footer
          }}
          role="presentation"
        >
          <List>
            <CreateResultManagementDetails examDetails={detailsData} />
          </List>
        </Box>

        {/* Fixed Footer with Submit Button */}
        {/* <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1100, // Ensure it stays above other elements
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#0d2238',
            color: '#ffffff',
            padding: 'px',
            borderTop: '1px solid #ccc',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: '#ffc107',
              color: '#0d2238',
              '&:hover': {
                backgroundColor: '#ffa000',
              },
            }}
            onClick={() => console.log('Submit button clicked')} // Replace with your submit logic
          >
            Submit
          </Button>
        </Box> */}
      </Drawer>


    </ThemeProvider>
  )
}

CreateResultmanagement.propTypes = {}

CreateResultmanagement.defaultProps = {}

export default CreateResultmanagement
