import React from 'react'
import { useAuth } from '../../services/authContext'
import {
  Container,
  Box,
  Fab,
  TextField,
  Button,
  Modal,
  Typography,
  Divider,
  List,
  ListItem,
  Autocomplete,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  ListProductApi,
  ListTeachersApi,
  CreateTeacherApi,
  UpdateTeacherApi,
  DeleteTeacherApi,
  ForgetPassscodeApi
} from '../../services/apis'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import './TeacherManagement.scss'
import { ArrowBack } from '@mui/icons-material'
import CryptoJS from 'crypto-js';
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../componentConst';
import TeacherForm from '../Utility/TeacherForm'

const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23; // Replace with a secure key

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString); // Parse the JSON string
  } catch (error) {
    console.error('Decryption error:', error);
    return null; // Return null if decryption fails
  }
};

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
    // Add more theme customizations as needed
  }
})

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vW',
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '240px'
}



const TeacherManagement = () => {
  const { loginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [classList, setCLassList] = React.useState([])
  const [selectedID, setSelectedID] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [dob, setDob] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [status, setStatus] = React.useState('Active')
  const [dateJoined, setDateJoined] = React.useState(Date.now())




  React.useEffect(() => {
    if (listApiFlag && loginStatus.user && loginStatus.user.staff_type === "admin") {
      const fetchListProducts = async () => {
        try {
          const encryptedCreds = localStorage.getItem('loginCred')

          if (!encryptedCreds) {
            throw new Error('No login credentials found in localStorage')
          }

          const decryptedCreds = decryptData(encryptedCreds)

          if (!decryptedCreds || !decryptedCreds.token) {
            throw new Error(
              'Failed to retrieve valid token from decrypted credentials'
            )
          }

          const authToken = JSON.stringify(decryptedCreds)
          const response = await ListTeachersApi(
            JSON.stringify({ adminToken: JSON.parse(authToken).token })
          )
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      const fetchListClasses = async () => {
        try {
          const response = await ListProductApi()
          response.forEach(element => {
            element.title = element.productName
          })
          console.log('getresponse', JSON.stringify(response))
          setCLassList(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
      fetchListClasses()
    }
  }, [loginStatus])

  const handleUpdateTask = async () => {
    try {
      const formData = JSON.stringify({
        item_id: selectedID,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        phone: phone,
        address: address,
        dob: new Date(dob).toISOString().split('T')[0],
        gender: gender,
        status: status,
        date_joined: dateJoined
      })

      const response = await UpdateTeacherApi(formData)
      console.log('Teacher created successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating teacher:', error)
    }
  }

  const handleDeleteProductModal = (item_id) => {
    setDeleteProductOpen(true);
    setSelectedID(item_id);
  };

  const filteredUsers = listOfUsers.filter(
    user =>
      user.first_name.toLowerCase().includes(filter.toLowerCase()) ||
      user.last_name.toLowerCase().includes(filter.toLowerCase())
  )


  const [open, setOpen] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)

  const [openForm, setOpenForm] = React.useState(false);
  const [formMode, setFormMode] = React.useState("create"); // 'create' or 'update'
  const [teacherData, setTeacherData] = React.useState({});

  const handleOpen = username => {
    console.log('yo yo usrname', username)
    setSelectedID(username.id)
    setFirstName(username.first_name)
    setLastName(username.last_name)
    setEmail(username.email)
    setAddress(username.address)
    setPhone(username.phone)
    setDateJoined(username.date_joined)
    setDob(username.dob)
    setGender(username.gender)
    setPassword(username.otp)
    setStatus(username.status)
    setOpen(true)
  }

  // const handleOpenCreate = async () => {
  //   const today = new Date()
  //   const formattedDate = today.toISOString().split('T')[0]
  //   setDateJoined(formattedDate)
  //   setOpenCreate(true)
  // }
  const handleOpenCreate = () => {
    setFormMode("create");
    setTeacherData({});
    setOpenForm(true);
  };

  const handleOpenUpdate = (data) => {
    setFormMode("update");
    setTeacherData(data);
    setOpenForm(true);
  };

  const handleTeacherSubmit = async (teacherData) => {
    try {
      const encryptedCreds = localStorage.getItem("loginCred");
      if (!encryptedCreds) throw new Error("No login credentials found");

      const decryptedCreds = decryptData(encryptedCreds);
      if (!decryptedCreds || !decryptedCreds.token) throw new Error("Invalid token");

      // ✅ Convert teacherData object into FormData
      const formData = new FormData();
      formData.append("first_name", teacherData.first_name);
      formData.append("last_name", teacherData.last_name);
      formData.append("email", teacherData.email);
      formData.append("phone", teacherData.phone);
      formData.append("address", teacherData.address);
      formData.append("dob", teacherData.dob);
      formData.append("gender", teacherData.gender);
      formData.append("status", teacherData.status || "Active");
      formData.append("date_joined", teacherData.dateJoined || new Date().toISOString().split("T")[0]);
      formData.append("adminToken", decryptedCreds.token);
      formData.append("school_name", loginStatus.admin.school_name)

      // ✅ If profile picture is selected, append it
      if (teacherData.profile_picture_file) {
        formData.append("profile_picture", teacherData.profile_picture_file);
      }

      // ✅ Different API calls for create and update
      if (formMode === "create") {
        formData.append("password", "1234"); // Default password
        console.log("FORMDATA:", formData.entries())
        await CreateTeacherApi(formData);
        ForgetPassscodeApi({ phone: teacherData.phone })
          .then(() => alert("OTP sent successfully!"))
          .catch(() => alert("Error sending OTP."));
      } else {
        formData.append("item_id", teacherData.id);
        await UpdateTeacherApi(formData);
      }

      window.location.reload();
    } catch (error) {
      console.error("Error creating/updating teacher:", error);
    }
  };


  const handleClose = () => {
    setOpen(false)
    setOpenCreate(false)
  }

  const handleCreateTeacher = async () => {
    try {
      const encryptedCreds = localStorage.getItem('loginCred')

      if (!encryptedCreds) {
        throw new Error('No login credentials found in localStorage')
      }

      const decryptedCreds = decryptData(encryptedCreds)

      if (!decryptedCreds || !decryptedCreds.token) {
        throw new Error(
          'Failed to retrieve valid token from decrypted credentials'
        )
      }

      const authToken = JSON.stringify(decryptedCreds)
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: '1234',
        staff_type: 'teacher',
        phone: phone,
        address: address,
        dob: new Date(dob).toISOString().split('T')[0],
        gender: gender,
        status: status,
        school_name: loginStatus.admin.school_name,
        date_joined: dateJoined,
        adminToken: JSON.parse(authToken).token
      }

      const response = await CreateTeacherApi(formData)
      console.log('Teacher created successfully:', response)
      ForgetPassscodeApi({ phone })
        .then(() => {
          alert('OTP sent successfully!')
          window.location.reload()
        })
        .catch(() => {
          alert('Error sending OTP. Please try again.')
        })
      handleClose()
    } catch (error) {
      console.error('Error creating teacher:', error)
    }
  }


  const handleDeleteProduct = async () => {
    try {
      const formData = new FormData();
      formData.append("id", selectedID);
      await DeleteTeacherApi(formData);
      setDeleteProductOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };


  return (
    <div className='TeacherManagement' data-testid='TeacherManagement'>
      <ThemeProvider theme={theme}>
        <Container
          sx={{
            flexGrow: 1,
            mt: { xs: 2, md: 5 }, // Margin for small and medium screens
            mb: { xs: 5, md: 7 }, // Adjust bottom margin
            paddingBottom: { xs: '80px', md: '80px' }
            // bgcolor: 'background.paper'
          }}
        >
          {loginStatus.token && (
            <Box>
              {loginStatus.user.staff_type === "admin" ? (
                <div className='table-cover'>
                  <Box sx={{ position: 'absolute', bottom: 76, right: 16 }}>
                    <Fab
                      color='primary'
                      aria-label='add'
                      onClick={handleOpenCreate}
                      sx={{
                        position: 'fixed',
                        right: '30px',
                        bottom: '70px'
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                  <TextField
                    fullWidth
                    label='Filter Class'
                    id='fullWidth'
                    value={filter}
                    sx={{ mb: 3, mt: 5 }}
                    onChange={e => setFilter(e.target.value)}
                  />
                  {filteredUsers
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(t => t.className === item.className)
                    )
                    .map(item => (
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1-content'
                          id='panel1-header'
                        >
                          List Of Teachers
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            <Thead>
                              <Tr>
                                <Th>Teacher Name</Th>
                                <Th>Teacher Phone</Th>
                                {/*<Th>Class Pics</Th>*/}
                                <Th>Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {filteredUsers.map(users => (
                                <>
                                  {users.className === item.className ? (
                                    <Tr key={users.id}>
                                      <Td>
                                        {users.first_name} {users.last_name}
                                      </Td>
                                      <Td>{users.phone}</Td>
                                      <Td>
                                        <Button
                                          variant="contained"
                                          onClick={() => handleOpenUpdate(users)}
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          variant='contained'
                                          color='error'
                                          className='btn'
                                          onClick={() =>
                                            handleDeleteProductModal(users.id)
                                          }
                                        >
                                          Delete
                                        </Button>
                                      </Td>
                                    </Tr>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </Tbody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    ))}

                  {/* DELETTION MODAL */}
                  <Modal
                    fullscreen
                    open={deleteProductOpen}
                    onClose={() => setDeleteProductOpen(false)}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                  >
                    <Box sx={{ ...style, position: 'relative' }}>
                      <CloseIcon
                        onClick={() => setDeleteProductOpen(false)}
                        style={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          cursor: 'pointer'
                        }}
                      />
                      <h3>Are you Sure You want to delete this Teacher</h3>
                      <Button
                        variant='contained'
                        className='btn'
                        onClick={() => {
                          handleDeleteProduct(selectedID)
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant='contained'
                        className='btn'
                        onClick={() => setDeleteProductOpen(false)}
                      >
                        No
                      </Button>
                    </Box>
                  </Modal>

                  {/* Create New Teacher Drawer */}
                  <TeacherForm
                    open={openForm}
                    onClose={() => setOpenForm(false)}
                    onSubmit={handleTeacherSubmit}
                    mode={formMode}
                    teacherData={teacherData}
                    setTeacherData={setTeacherData}
                  />


                </div>
              ) : (
                <div className='task-cover'>
                  {filteredUsers.map(item => (
                    <>
                      <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                      <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                        <ListItem alignItems='flex-start'>
                          <ListItemText
                            primary={item.taskHeading}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component='span'
                                  variant='body2'
                                  sx={{
                                    color: 'text.primary',
                                    display: 'inline'
                                  }}
                                >
                                  {item.taskDescription}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        {item.amount > 0 ? (
                          <Button component='a' href={item.paymentLink}>
                            Pay Rs. {item.amount}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </List>
                      <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                    </>
                  ))}
                </div>
              )}
            </Box>
          )}
        </Container>
      </ThemeProvider>
    </div>
  )
}

TeacherManagement.propTypes = {}

TeacherManagement.defaultProps = {}

export default TeacherManagement
