import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from '../../services/authContext';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Container,
  Fab,
  Card,
  CardMedia,
  Button,
  Modal,
  Fade,
  Grid,
  Popover,
  useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { BEBase } from '../../constants/app.constant';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UploadImageApi, deleteImageApi, ListImagesApi, DeleteImageApi } from '../../services/apis'; // Import the upload and delete image APIs
import CloseIcon from '@mui/icons-material/Close';
import TopBackBar from '../Utility/TopBackBar';

const theme = createTheme({
  palette: {
    primary: { main: '#284d84' }, // Blue color
    secondary: { main: '#EC4899' }, // Pink color
  },
  typography: { fontFamily: 'Arial, sans-serif' },
});

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%", // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '190px',
  '@media (max-width: 600px)': { // Adjust the breakpoint as needed
    width: '100%', // Width for mobile devices
  }
};

const GalleryManagement = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [showText, setShowText] = useState(true); // New state to control visibility
  const fileInputRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [selectedID, setSelectedId] = React.useState(0);
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false);
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    console.log(listOfUsers.length)
    if (listOfUsers.length === 0) {
      setShowText(true)
    }
    else {
      setShowText(false)
    }
  }, [listOfUsers])


  useEffect(() => {
    if (loginStatus && listApiFlag && loginStatus.user) {
      const fetchListProducts = async () => {
        try {
          const response = await ListImagesApi({
            id: JSON.parse(loginStatus.user.id)
          });
          setListOfUsers(response);
          setListApiFlag(false);
        } catch (error) {
          console.error('Error fetching users:', error);
          setListApiFlag(false);
        }
      };

      fetchListProducts();
    }
  }, [loginStatus, listApiFlag]);

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleFabClick = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleCameraClick = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // Check if an existing video element already exists
        let videoElement = document.getElementById('cameraStream');
        if (!videoElement) {
          // Create a new video element
          videoElement = document.createElement('video');
          videoElement.id = 'cameraStream';
          videoElement.style.position = 'fixed';
          videoElement.style.bottom = '10px';
          videoElement.style.right = '10px';
          videoElement.style.width = '300px';
          videoElement.style.zIndex = '1000';
          document.body.appendChild(videoElement);
        }

        videoElement.srcObject = stream;
        videoElement.play();
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });

    // Close the popover (assuming `setPopoverAnchor` manages its visibility)
    setPopoverAnchor(null);
  };


  const handleUploadClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
    setPopoverAnchor(null);
  };

  const handleImageSelect = async (e) => {
    const formData = new FormData()

    // Append text fields
    formData.append('image', e.target.files[0])
    formData.append('id', loginStatus.user.id)
    if (formData) {
      try {
        // Call the API to upload the image
        const response = await UploadImageApi(formData);
        //console.log('Gallery Image created successfully:', response)
        window.location.reload()
        //handleClose()
      } catch (error) {
        console.error('Error creating Gallery Image:', error)
      }
    };
  }

  const handleDeleteImage = async (imageToDelete) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedID);

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteImageApi(formData);

      console.log("Product deleted successfully:", response);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Error deleting Product:", error);
    }
  };

  const handleModalOpen = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };


  const handleModalClose = () => {
    setSelectedImage(null);
    setOpenModal(false);
  };

  return (
    <ThemeProvider theme={theme}>


      <Container
        sx={{
          flexGrow: 1,
          mt: { xs: 2, md: 5 }, // Margin for small and medium screens
          mb: { xs: 5, md: 7 }, // Adjust bottom margin
          paddingBottom: { xs: '80px', md: '80px' },
          // bgcolor: 'background.paper',
          minHeight: "80vh"
        }}
      >
        {/* Conditionally render the typography based on showText */}
        {showText && (
          <>
            <Typography
              variant='h4'
              component='h1'
              gutterBottom
              fontWeight='bold'
              sx={{
                mt: 2,
                textAlign: 'center',
                mx: 'auto',
                maxWidth: '90%',
                marginTop: isMobile ? "42px" : "170px"
              }}>
              Click a Photo
              or <Box component="span" color="primary.main">browse to upload.</Box>
            </Typography>


            <Typography variant="body2" color="text.secondary" sx={{ mt: 2, textAlign: "center" }}>
              File must be JPEG, JPG or PNG and up to 40MB
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mt: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                <CheckCircleIcon sx={{ marginLeft: isMobile ? "10px" : "0px" }} color="secondary" fontSize="small" />
                <Typography sx={{ marginLeft: isMobile ? "10px" : "0px" }} variant="body2">High Picture Quality</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CheckCircleIcon color="secondary" fontSize="small" />
                <Typography variant="body2">Like and Save whenever you want</Typography>
              </Box>
            </Box>

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              By uploading your image or video, read the {' '}
              <Link href="#" color="primary">
                Terms of use
              </Link>
              {' '}and{' '}
              <Link href="#" color="primary">
                Privacy Policy
              </Link>
            </Typography>
          </>
        )}


        <Grid container spacing={2} justifyContent="center" marginTop={'40px'} paddingBottom={'60px'}>
          {listOfUsers.map((imageUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => handleModalOpen(BEBase + 'media/' + imageUrl.image + `?_=${new Date().getTime()}`)}
              >
                <CardMedia component="img" image={BEBase + 'media/' + imageUrl.image + `?_=${new Date().getTime()}`} alt={`Uploaded Image ${index + 1}`} sx={{ height: 200, objectFit: 'cover' }} />
                {loginStatus.user.staff_type !== "student" &&
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedId(imageUrl.id);
                      setDeleteProductOpen(true);
                    }}
                    sx={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              </Card>
            </Grid>
          ))}
        </Grid>

        <Modal
          fullscreen
          open={deleteProductOpen}
          onClose={() => setDeleteProductOpen(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={{ ...ImgStyle, position: 'relative' }}>
            <CloseIcon
              onClick={() => setDeleteProductOpen(false)}
              style={{
                position: 'absolute',
                top: 16,
                right: 16,
                cursor: 'pointer'
              }}
            />
            <h3>Are you Sure You want to delete this Gallery Image</h3>
            <Button
              variant='contained'
              className='btn'
              onClick={() => {
                handleDeleteImage()
              }}
            >
              Yes
            </Button>
            <Button
              variant='contained'
              className='btn'
              onClick={() => setDeleteProductOpen(false)}
            >
              No
            </Button>
          </Box>
        </Modal>

        <Modal
          open={openModal}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              backdropFilter: 'blur(4px)'
            }
          }}
        >
          <Fade in={openModal}>
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '90vw', // Responsive max width
                maxHeight: '90vh', // Responsive max height
                width: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 2,
                borderRadius: 2,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {/* Close Button */}
              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  zIndex: 1,
                  backgroundColor: "#284d84",
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>

              {selectedImage && (
                <CardMedia
                  component="img"
                  image={selectedImage}
                  alt="Selected Image"
                  sx={{
                    maxWidth: '100%',
                    maxHeight: '80vh',
                    width: 'auto',
                    height: 'auto',
                    objectFit: 'contain',
                    borderRadius: 1,
                    margin: 'auto'
                  }}
                />
              )}
            </Box>
          </Fade>
        </Modal>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleImageSelect}
        />
        {loginStatus.user.staff_type !== "student" &&

          <Fab
            color="primary"
            sx={{ position: 'fixed', bottom: 76, right: 26 }}
            onClick={handleFabClick}
          >
            <AddIcon />
          </Fab>}

        <Popover
          open={Boolean(popoverAnchor)}
          anchorEl={popoverAnchor}
          onClose={() => setPopoverAnchor(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CloudUploadIcon />}
              onClick={handleUploadClick}
            >
              Upload Picture
            </Button>
          </Box>
        </Popover>
      </Container>

    </ThemeProvider >
  );
};

GalleryManagement.propTypes = {};
GalleryManagement.defaultProps = {};

export default GalleryManagement;
