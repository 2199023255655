import React from 'react'
import './Settings.scss'
import { useAuth } from '../../services/authContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import FolderIcon from '@mui/icons-material/Folder'
import LogoutIcon from '@mui/icons-material/Logout'
import GavelIcon from '@mui/icons-material/Gavel'
import PolicyIcon from '@mui/icons-material/Policy'
import LockResetIcon from '@mui/icons-material/LockReset'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom'
import { Box, Modal, Drawer } from '@mui/material'
import SignupForm from '../SignupForm/SignupForm'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c97c3'
    },
    background: {
      default: '#f5c44e',
      paper: '#f5c44e'
    }
  }
})

const style = {
  position: 'fixed',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH'
}

const Settings = () => {
  const navigate = useNavigate()
  const { loginStatus, setLoginStatus } = useAuth()
  const [open, setOpen] = React.useState(false)


  const logOut = () => {
    localStorage.removeItem('loginCred')
    navigate('/')
    window.location.reload()
  }

  const tnc = () => {
    navigate('/terms')
  }

  const pp = () => {
    navigate('/privacy')
  }

  return (
    <>
      {loginStatus.token ? (
        <div className='Settings perfect-center' data-testid='Settings'>
          <List>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <LockResetIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary='Update Profile' sx={{ fontWeight: 700 }} />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={logOut} sx={{ cursor: 'pointer' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <LogoutIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary='Log Out' sx={{ fontWeight: 700 }} />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={tnc} sx={{ cursor: 'pointer' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <GavelIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary='Terms' sx={{ fontWeight: 700 }} />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={pp} sx={{ cursor: 'pointer' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <PolicyIcon color='primary' />
              </ListItemIcon>
              <ListItemText
                primary='Privacy and Other Policies'
                sx={{ fontWeight: 700 }}
              />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
          </List>
          <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
            <Box
              sx={{
                width: '100vw',
                bgcolor: 'white',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                sx={{
                  bgcolor: '#0d2238',
                  color: 'white',
                  padding: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <h2 style={{ margin: 0 }}>Update Profile</h2>
                <CloseIcon
                  onClick={() => setOpen(false)}
                  style={{
                    cursor: 'pointer',
                    color: 'white'
                  }}
                />
              </Box>
              <Box
                sx={{
                  padding: '16px',
                  flex: 1,
                  overflowY: 'auto'
                }}
              >
                <SignupForm />
              </Box>
            </Box>
          </Drawer>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Settings
