import { BEBase } from '../constants/app.constant';
import CryptoJS from 'crypto-js';
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../components/componentConst';


const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23; // Replace with a secure key

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString); // Parse the JSON string
  } catch (error) {
    console.error('Decryption error:', error);
    return null; // Return null if decryption fails
  }
};

export const LoginApi = request => {
  const url = BEBase + 'login/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Invalid Login Credentials')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Invalid Login Credentials', error)
      throw error // Rethrow the error for further handling
    })
}

export const FetchUsernamesApi = request => {
  const url = BEBase + 'fetch_usernames/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Invalid Login Credentials')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Invalid Login Credentials', error)
      throw error // Rethrow the error for further handling
    })
}

export const ForgetPassscodeApi = request => {
  const url = BEBase + 'forget_password/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Invalid Login Credentials')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Invalid Login Credentials', error)
      throw error // Rethrow the error for further handling
    })
}

export const VerifyOtpApi = request => {
  const url = BEBase + 'verify_otp/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Invalid Login Credentials')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Invalid Login Credentials', error)
      throw error // Rethrow the error for further handling
    })
}

export const RegisterApi = request => {
  const url = BEBase + 'signup/'

  return fetch(url, {
    method: 'POST',
    headers: {
      // 'Content-Type': 'multipart/form-data'
    },
    body: request
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateUserApi = (request, id) => {
  const url = BEBase + `update_user/${id}/`

  return fetch(url, {
    method: 'PUT',
    body: request
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error
    })
}

export const GetUserApi = id => {
  const url = `${BEBase}get_user/${id}/`

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch(error => {
      console.log(error, 'error')
      console.error('There was a problem with the fetch operation:', error)
      throw error
    })
}

export const ListUsersApi = request => {
  const url = BEBase + 'list_users/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTeachersApi = request => {
  const url = BEBase + 'list_teacher/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: request
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateTaskApi = request => {
  const url = BEBase + 'create_paid_task/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({
      username: request.username,
      amount: request.amount,
      taskHeading: request.taskHeading,
      taskDescription: request.taskDescription,
      paymentLink: request.paymentLink
    })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTaskApi = request => {
  const url = BEBase + 'list_user_task/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateCourseApi = formData => {
  const url = BEBase + 'create_product/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Section Not Added or Some Other error', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListProductApi = request => {
  const url = BEBase + 'list_product/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({ id: JSON.parse(authToken).user.id })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateCourseApi = formData => {
  const url = BEBase + 'update_product/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteCourseApi = formData => {
  const url = BEBase + 'delete_product/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteSectionApi = formData => {
  const url = BEBase + 'delete_section/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateCourseImageApi = formData => {
  const url = BEBase + 'update_product_image/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateSubjectApi = formData => {
  const url = BEBase + 'create_subject/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListSubjectApi = (request) => {
  const url = BEBase + 'list_subject/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  console.log(request)
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({ adminToken: JSON.parse(authToken).token, id: request })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteSubjectApi = formData => {
  const url = BEBase + 'delete_subject/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateSubjectApi = formData => {
  const url = `${BEBase}update_subject/?_=${new Date().getTime()}` // Append a timestamp
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateFeesApi = formData => {
  const url = BEBase + 'create_fees/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateTeacherApi = formData => {
  const url = BEBase + 'create_teacher/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListFeesApi = (admin_id,isStudent=false) => {
  const url = BEBase + 'list_fees/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({ id:admin_id,isStudent  })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteFeesApi = formData => {
  const url = BEBase + 'delete_fees/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateFeesApi = formData => {
  const url = `${BEBase}update_fees/?_=${new Date().getTime()}` // Append a timestamp
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateTransportApi = formData => {
  const url = BEBase + 'create_transport/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTransportApi = request => {
  const url = BEBase + 'list_transport/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteTransportApi = formData => {
  const url = BEBase + 'delete_transport/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateTransportApi = formData => {
  const url = `${BEBase}update_transport/?_=${new Date().getTime()}` // Append a timestamp
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateTeacherApi = formData => {
  const url = BEBase + 'update_teacher/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteTeacherApi = formData => {
  const url = BEBase + 'delete_teacher/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}
export const ListTimeTableApi = request => {
  const url = BEBase + 'list_timeTable/'
  const encryptedCreds = localStorage.getItem('loginCred')

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage')
  }

  const decryptedCreds = decryptData(encryptedCreds)

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials')
  }

  const authToken = JSON.stringify(decryptedCreds)

  // Add var2 to the request object
  const extendedRequest = {
    ...request,
    "id": JSON.parse(authToken).token
  }
  console.log(extendedRequest)
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(extendedRequest)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error
    })
}



export const GetStudentDetail = request => {
  const url = BEBase + 'view_detail/'
  const encryptedCreds = localStorage.getItem('loginCred')

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage')
  }

  const decryptedCreds = decryptData(encryptedCreds)

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials')
  }

  const authToken = JSON.stringify(decryptedCreds)

  // Add var2 to the request object
  const extendedRequest = {
    ...request,
    // "id": JSON.parse(authToken).token
  }
  console.log(extendedRequest)
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(extendedRequest)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error
    })
}

export const ListClassRelatedToSectionApi = request => {
  const url = BEBase + 'class_related_to_section/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListSubjectFromClassNameApi = request => {
  const url = BEBase + 'list_subject_from_className/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListSubjectFromClassNameIdApi = request => {
  const url = BEBase + 'list_subject_from_className_id/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateTimeTableApi = formData => {
  const url = BEBase + 'update_timeTable/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const TeacherAttendanceApi = formData => {
  const url = BEBase + 'teacher_attendance/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTeacherAttendanceApi = request => {
  const url = BEBase + 'list_teacher_attendance/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const StudentAttendanceApi = formData => {
  const url = BEBase + 'student_attendance/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListStudentAttendanceApi = request => {
  const url = BEBase + 'list_student_attendance/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateStudentApi = formData => {
  const url = BEBase + 'update_student/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

// API Call Function for Updating Homework
export const UpdateStudentHomeworkApi = async formData => {
  const url = BEBase + 'update_student_homework/' // Your API endpoint
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Token ' + JSON.parse(authToken).token
      },
      body: formData // Send the FormData object directly
    })

    if (!response.ok) {
      const errorData = await response.json()
      console.log('Error details:', errorData)
      throw new Error('Network response was not ok')
    }

    return response.json() // Return the parsed JSON response if successful
  } catch (error) {
    console.error('Error during the fetch operation:', error)
    throw error // Propagate the error for further handling
  }
}

export const DeleteStudentApi = formData => {
  const url = BEBase + 'delete_student/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateStudentApi = formData => {
  const url = BEBase + 'create_student/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListStudentsApi = request => {
  const url = BEBase + 'list_student/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListStudentsSectionApi = request => {
  const url = BEBase + 'list_student_section/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListStudentsApiTeacher = request => {
  const url = BEBase + 'list_student_teacher/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateStudentHomeworkApi = formData => {
  const url = BEBase + 'create_student_homework/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListStudentHomeworkApi = request => {
  const url = BEBase + 'list_student_homework/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTeacherTimetableApi = async request => {
  const url = BEBase + 'list_teacher_timeTable/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + JSON.parse(authToken).token
      },
      body: JSON.stringify(request)
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const data = await response.text() // Use text() to log raw response
    console.log('Raw API Response:', data)

    const jsonData = JSON.parse(data) // Explicitly parse JSON
    return jsonData
  } catch (error) {
    console.error('API Fetch Error:', error)
    throw error
  }
}

export const ListSectionFromIdApi = request => {
  const url = BEBase + 'list_section_from_id/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListClassFromIdApi = request => {
  const url = BEBase + 'list_class_from_id/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListSubjectFromIdApi = request => {
  const url = BEBase + 'list_subject_from_id/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteStudentHomeworkApi = formData => {
  const url = BEBase + 'delete_student_homework/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateNoticeApi = formData => {
  const url = BEBase + 'create_notice/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListNoticeApi = request => {
  const url = BEBase + 'list_notice/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateNoticeApi = formData => {
  const url = BEBase + 'update_notice/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteNoticeApi = formData => {
  const url = BEBase + 'delete_notice/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

// Upload image API
export const UploadImageApi = formData => {
  const url = BEBase + 'upload_image/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListImagesApi = request => {
  const url = BEBase + 'list_images/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

// Delete image API
export const DeleteImageApi = formData => {
  const url = BEBase + 'delete_image/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListVideoApi = request => {
  const url = BEBase + 'list_video/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

// export const for video-related API calls

export const CreateVideoApi = request => {
  const url = BEBase + 'add_video/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteVideoApi = formData => {
  const url = BEBase + 'delete_video/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateVideoApi = formData => {
  const url = BEBase + 'update_video/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: JSON.stringify(formData) // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateExamApi = request => {
  const url = BEBase + 'create_exam/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListExamApi = request => {
  const url = BEBase + 'list_exam/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const GetStudentResultApi = request => {
  const url = BEBase + 'view_result/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateExamApi = request => {
  const url = BEBase + 'update_exam/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      //console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteExamApi = request => {
  const url = BEBase + 'delete_exam/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload() // Return the parsed JSON response
    })
    .catch(error => {
      //console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListCreateResultApi = request => {
  const url = BEBase + 'list_createResult/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateCreateResultApi = request => {
  const url = BEBase + 'create_createResult/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateAddMarksApi = formData => {
  const url = BEBase + 'update_addMarks/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListAddMarksApi = request => {
  const url = BEBase + 'list_addMarks/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateCreateResultApi = request => {
  const url = BEBase + 'list_updateResult/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteCreateResultApi = formData => {
  const url = BEBase + 'delete_createResult/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdatePublishResultApi = formData => {
  const url = BEBase + 'update_publishResult/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      //window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateTopImageApi = formData => {
  const url = BEBase + 'create_top_image/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      //window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTopImageApi = formData => {
  const url = BEBase + 'list_top_image/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  // Log the request payload
  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token // Only send authorization header
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Assuming the server sends a JSON response
    })
    .then(data => {
      // Process the JSON response here (e.g., log or return it)
      console.log('Response data:', data)
      return data
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteTopImageApi = formData => {
  const url = BEBase + 'delete_top_image/'
  const encryptedCreds = localStorage.getItem('loginCred');

  if (!encryptedCreds) {
    throw new Error('No login credentials found in localStorage');
  }

  const decryptedCreds = decryptData(encryptedCreds);

  if (!decryptedCreds || !decryptedCreds.token) {
    throw new Error('Failed to retrieve valid token from decrypted credentials');
  }

  const authToken = JSON.stringify(decryptedCreds);

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const FetchDataFromOtpApi = request => {
  const url = BEBase + 'fetch_data_from_otp/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Invalid Login Credentials')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Invalid Login Credentials', error)
      throw error // Rethrow the error for further handling
    })
}
